<template>
	<div class="ho-month-year-selector flex items-center">
		<div class="ho-selector-month">
			<Autocomplete
				class="ui-test-autocomplete-month"
				:placeholder="
					localization.t('month-year-selector.placeholders.select-month')
				"
				:options="monthsOptions"
				:defaultValue="monthRef"
				:canClear="false"
				@onChange="handleMonthSelect"
			/>
		</div>

		<div class="ho-selector-year">
			<Autocomplete
				class="ml-3 ui-test-autocomplete-year"
				:placeholder="
					localization.t('month-year-selector.placeholders.select-year')
				"
				:options="yearsOptions"
				:defaultValue="yearRef"
				:canClear="false"
				@onChange="handleYearsSelect"
			/>
		</div>
	</div>
</template>

<script setup lang="ts">
import { ref, watch, computed } from "vue";
import { useI18n } from "vue-i18n";
import Autocomplete from "./autocomplete.vue";

const localization = useI18n();
const currDate = new Date();

const props = defineProps({
	defaultMonth: {
		type: Number,
		default: null,
	},
	defaultYear: {
		type: Number,
		default: null,
	},
});

const getTranslatedMonths = (): string[] => {
	return [
		localization.t("month-year-selector.months.january"),
		localization.t("month-year-selector.months.february"),
		localization.t("month-year-selector.months.march"),
		localization.t("month-year-selector.months.april"),
		localization.t("month-year-selector.months.may"),
		localization.t("month-year-selector.months.june"),
		localization.t("month-year-selector.months.july"),
		localization.t("month-year-selector.months.august"),
		localization.t("month-year-selector.months.september"),
		localization.t("month-year-selector.months.october"),
		localization.t("month-year-selector.months.november"),
		localization.t("month-year-selector.months.december"),
	];
};
const emit = defineEmits(["onChange"]);

const monthRef = ref(props.defaultMonth || currDate.getMonth());
const yearRef = ref(props.defaultYear || currDate.getFullYear());
const monthsNames = ref(getTranslatedMonths());

const yearsOptions: number[] = [];
for (let i = 2021; i <= currDate.getFullYear(); i++) {
	yearsOptions.push(i);
}

watch(
	() => localization.locale.value,
	() => {
		monthsNames.value = getTranslatedMonths();
	}
);

const monthsOptions = computed(() => {
	return monthsNames.value.map((m, i) => ({
		label: m,
		value: i,
	}));
});

const handleMonthSelect = (month: number) => {
	monthRef.value = month;
	valuesChanged(month, yearRef.value);
};

const handleYearsSelect = (year: number) => {
	yearRef.value = year;
	valuesChanged(monthRef.value, year);
};

const valuesChanged = (month: number, year: number) => {
	emit("onChange", month, year);
};
</script>

<style lang="postcss">
.ho-selector-month {
	width: 140px;
}
.ho-selector-year {
	width: 100px;
}
</style>
