import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  class: "flags-icons",
  id: "flags",
  viewBox: "0 0 512 512"
}
const _hoisted_2 = {
  key: 0,
  "fill-rule": "evenodd",
  "stroke-width": "1pt"
}
const _hoisted_3 = {
  key: 1,
  "fill-rule": "evenodd",
  "stroke-width": "1pt"
}


export default /*@__PURE__*/_defineComponent({
  __name: 'language-flags',
  props: {
	selectedLanguage: {
		type: String,
		default: localStorage.getItem('lang')
	}
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    (__props.selectedLanguage==='bg')
      ? (_openBlock(), _createElementBlock("g", _hoisted_2, _cache[0] || (_cache[0] = [
          _createElementVNode("path", {
            fill: "#d62612",
            d: "M0 341.3h512V512H0z"
          }, null, -1),
          _createElementVNode("path", {
            fill: "#fff",
            d: "M0 0h512v170.7H0z"
          }, null, -1),
          _createElementVNode("path", {
            fill: "#00966e",
            d: "M0 170.7h512v170.6H0z"
          }, null, -1)
        ])))
      : _createCommentVNode("", true),
    (__props.selectedLanguage==='en')
      ? (_openBlock(), _createElementBlock("g", _hoisted_3, _cache[1] || (_cache[1] = [
          _createStaticVNode("<path fill=\"#012169\" d=\"M0 0h512v512H0z\" data-v-7c034db7></path><path fill=\"#FFF\" d=\"M512 0v64L322 256l190 187v69h-67L254 324 68 512H0v-68l186-187L0 74V0h62l192 188L440 0z\" data-v-7c034db7></path><path fill=\"#C8102E\" d=\"m184 324 11 34L42 512H0v-3l184-185zm124-12 54 8 150 147v45L308 312zM512 0 320 196l-4-44L466 0h46zM0 1l193 189-59-8L0 49V1z\" data-v-7c034db7></path><path fill=\"#FFF\" d=\"M176 0v512h160V0H176zM0 176v160h512V176H0z\" data-v-7c034db7></path><path fill=\"#C8102E\" d=\"M0 208v96h512v-96H0zM208 0v512h96V0h-96z\" data-v-7c034db7></path>", 5)
        ])))
      : _createCommentVNode("", true)
  ]))
}
}

})