import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, isRef as _isRef, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ho-autocomplete" }

import { ref, watch, Ref } from "@vue/runtime-core";
import Multiselect from '@vueform/multiselect';
import { i18n } from '@/i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'autocomplete',
  props: {
	placeholder: {
		type: String,
		default: () => i18n.global.t('autocomplete.select-options')
	},
	options: {
		type: Array,
		default: () => []
	},
	defaultValue: {
		type: [String, Number],
		default: () => null
	},
	canClear: {
		type: Boolean,
		default: () => true
	}
},
  emits: ["onChange"],
  setup(__props, { emit: __emit }) {

const props = __props;
const emit = __emit;
const value: Ref<string | number> = ref(props.defaultValue);

watch(() => props.defaultValue, () => value.value = props.defaultValue);

const handleSelect = (val: any) => {
	emit("onChange", val)
}

const handleClear = () => {
	emit("onChange", null)
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(Multiselect), {
      modelValue: _unref(value),
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(value) ? (value).value = $event : null)),
      searchable: true,
      placeholder: __props.placeholder,
      options: __props.options,
      canClear: __props.canClear,
      canDeselect: false,
      onSelect: handleSelect,
      onClear: handleClear
    }, null, 8, ["modelValue", "placeholder", "options", "canClear"])
  ]))
}
}

})