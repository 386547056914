import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = ["autocomplete"]
const _hoisted_2 = { class: "ho-form-footer" }
const _hoisted_3 = ["disabled"]
const _hoisted_4 = ["disabled"]

import { i18n } from '@/i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'ho-form',
  props: {
	hideCancel: {
		type: Boolean,
		default: false
	},
	hideOk: {
		type: Boolean,
		default: false
	},
	okButtonText: {
		type: String,
		default: i18n.global.t('common.ok')
	},
	cancelButtonText: {
		type: String,
		default: i18n.global.t('common.cancel')
	},
	disableOk: {
		type: Boolean,
		default: false
	},
	disableCancel: {
		type: Boolean,
		default: false
	},
	disableAutocomplete: {
		type: Boolean,
		default: false
	}
},
  emits: ['onOk', 'onCancel'],
  setup(__props, { emit: __emit }) {


const emit = __emit;
//handle Dirty state

const handleOk = ()=>{
	emit('onOk');
}
const handleCancel = ()=>{
	emit('onCancel');
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"])),
    autocomplete: __props.disableAutocomplete ? 'off' : 'on'
  }, [
    _renderSlot(_ctx.$slots, "body"),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "footer", {}, () => [
        (!__props.hideOk)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              type: "button",
              class: "btn",
              onClick: handleOk,
              disabled: __props.disableOk
            }, _toDisplayString(__props.okButtonText), 9, _hoisted_3))
          : _createCommentVNode("", true),
        (!__props.hideCancel)
          ? (_openBlock(), _createElementBlock("button", {
              key: 1,
              type: "button",
              class: "btn btn-outline",
              onClick: handleCancel,
              disabled: __props.disableCancel
            }, _toDisplayString(__props.cancelButtonText), 9, _hoisted_4))
          : _createCommentVNode("", true)
      ])
    ])
  ], 40, _hoisted_1))
}
}

})