import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

import { ref, watch } from "vue";
import IInvoice from "@/interfaces/invoices/iInvoice";
import gridRow from "./grid-row.vue";
import IGridHeadCellConfig from "./interfaces/iGridHeadCellConfig";


export default /*@__PURE__*/_defineComponent({
  __name: 'grid-body',
  props: {
	data: Array as () => IInvoice[],
	columnsConfig: {
		type: Array as () => IGridHeadCellConfig[],
	},
	selectedItemIds: {
		type: Array,
		default: () => [],
	},
	selectionExcludingItemIds: {
		type: Array,
		default: () => [],
	},
	selectAllMode: Boolean,
	loading: {
		type: Boolean,
		default: () => false,
	},
	selectable: {
		type: Boolean,
		default: () => false,
	},
},
  emits: ["onSelectionChange"],
  setup(__props, { emit: __emit }) {

const props = __props;
const emit = __emit;
const checkedRows = ref(props.selectedItemIds);

watch(
	() => props.selectedItemIds.length,
	() => {
		if (props.selectable) {
			checkedRows.value = [...props.selectedItemIds];
		}
	},
	{
		immediate: true,
	}
);

watch(
	() => props.selectAllMode,
	(current, prev) => {
		if (props.selectable) {
			if (prev === false && current === true) {
				checkedRows.value = [...(props.data || []).map((d) => d.number)];
			} else {
				checkedRows.value = [];
			}
		}
	},
	{
		immediate: true,
	}
);

const handleCheckboxChange = (id: string) => {
	if (props.selectable) {
		emit("onSelectionChange", id);
	}
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("tbody", {
    class: _normalizeClass([{
			notSelectable: !__props.selectable,
			loading: __props.loading,
			'ho-grid-tbody': !__props.loading,
		}, "ui-test-grid-table-body"])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(!__props.loading ? __props.data : [1, 2, 3], (item, index) => {
      return (_openBlock(), _createBlock(gridRow, {
        key: `data-${index}`,
        data: item,
        loading: __props.loading,
        columnsConfig: __props.columnsConfig,
        selectable: __props.selectable,
        checkedRows: checkedRows.value,
        onOnCheckboxChange: handleCheckboxChange
      }, null, 8, ["data", "loading", "columnsConfig", "selectable", "checkedRows"]))
    }), 128))
  ], 2))
}
}

})