import { i18n } from "./i18n";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/global-styles.css";

createApp(App)
	.use(i18n as any)
	.use(router)
	.mount("#app");
