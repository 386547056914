import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "form-row transparent"
}
const _hoisted_2 = { class: "flex items-end" }
const _hoisted_3 = { class: "autocomplete-holder" }
const _hoisted_4 = ["disabled"]
const _hoisted_5 = { class: "w-8 h-8 text-center" }
const _hoisted_6 = { class: "w-8 h-8 text-center" }

import { computed, ref, Ref } from 'vue';
import { useI18n } from 'vue-i18n';
import HoModalDialog from "../modal/ho-modal-dialog.vue";
import IConnection from '@/interfaces/users/iConnection';
import Autocomplete from '../form/autocomplete.vue';
import { PlusIcon, PencilAltIcon } from '@heroicons/vue/outline';
import EditConnectionForm from '@/components/app/edit-connection-form.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'connections-dialog',
  props: {
	connections: {
		type: Array as () => IConnection[],
		default: () => []
	},
	selectedConnection: {
		type: Object as () => IConnection | null,
		default: () => null
	},
	loading: {
		type: Object,
		default: () => {
			return {
				connect: false,
				connection: false,
				user: false
			}
		}
	},
	hideCloseButton: Boolean
},
  emits: ['onConnectionsDialogClose', 'onEditConnectionsClose'],
  setup(__props, { emit: __emit }) {

const localization = useI18n();

const newConnectionTemplate: IConnection = {
	db: '',
	active: false,
	password: '',
	url: '',
	username: '',
	version: 14,
	alias: ''
};

const props = __props;
const emit = __emit;
const resetValidation = ref(false);
const editMode = ref(false);
const showEditForm = ref(false);

const activeConnectionIndex = props.connections.findIndex(c => c.active);
const indexOfCurrentConnection: Ref<number | null> = ref(activeConnectionIndex);

const connectionsDialogLabel = computed(() => {
	let label = localization.t('connections-dialog.my-connections');
	if (props.loading.connect) {
		label = localization.t('common.connecting');
	} else if (props.loading.user || props.loading.connection) {
		label = localization.t('connections-dialog.loading');
	} else if (showEditForm.value) {
		if (editMode.value) {
			label = localization.t('connections-dialog.edit');
		} else {
			label = localization.t('connections-dialog.create');
		}
		label = `${label} ${localization.t('connections-dialog.connection')}`;
	}
	return label;
})

const options = computed(() => {
	if (props.connections) {
		return props.connections.map((conn: IConnection) => ({label: conn.alias? conn.alias : conn.db, value: conn.db}))
	} else {
		return [];
	}
});

const currentConnection = computed(() => {
	if (indexOfCurrentConnection.value !== null) {
		return props.connections[indexOfCurrentConnection.value];
	}

	return null;
});

const disableConnectButton = computed(() => {
	return props.loading.connection
		|| props.loading.connect
		|| props.loading.user
		|| showEditForm.value
		|| (currentConnection.value?.db ? currentConnection.value?.db.trim().length === 0 : true);
});
const disableEditButton = computed(() => {
	return props.loading.connection
		|| props.loading.connect
		|| props.loading.user
		|| (showEditForm.value && editMode.value)
		|| (currentConnection.value?.db ? currentConnection.value?.db.trim().length === 0 : true);
});

const showSelectConnection = computed(() => {
	let show = !showEditForm.value;
	return show;
});

const handleModalCancel = () => {
	emit('onConnectionsDialogClose');
};
const handleModalOk = () => {
	emit('onConnectionsDialogClose', currentConnection.value, true);
};

const handleEditConnectionClose = (connection?: IConnection) => {
	if (!connection) {
		emit('onEditConnectionsClose');
		showEditForm.value = false;

		return;
	}

	if (editMode.value === true) {
		editConnection(connection);
	} else {
		addConnection(connection);
	}
}

const addConnection = (connection: IConnection) => {
	const connections = [...props.connections];

	connections.push(connection);
	
	indexOfCurrentConnection.value = connections.length - 1;

	emit('onEditConnectionsClose', connections, false, () => showEditForm.value = false);
}

const editConnection = (connection: IConnection) => {	
	const connections = [...props.connections];

	if (indexOfCurrentConnection.value === null) {
		return;
	}

	connections.splice(indexOfCurrentConnection.value, 1, connection);
	emit('onEditConnectionsClose', connections, false, () => showEditForm.value = false);
}

const handleConnectionSelect = (selectedConnectionDb: string) => {
	editMode.value = showEditForm.value ? true : editMode.value;
	resetValidation.value = showEditForm.value ? true : false;

	const selectedConnection = {...props.connections?.find(conn => 
		conn.db === selectedConnectionDb
	)} as IConnection;

	if (selectedConnection) {
		indexOfCurrentConnection.value = props.connections?.findIndex(c => c.db === selectedConnection.db);
	} else {
		indexOfCurrentConnection.value = null;
	}
}

const handleAddNewButtonClick = () => {
	indexOfCurrentConnection.value = null;
	editMode.value = false;
	resetValidation.value = showEditForm.value ? true : false;
	showEditForm.value = true;
}

const handleEditModeClick = () => {
	editMode.value = true;
	resetValidation.value = showEditForm.value ? true : false;
	showEditForm.value = true;
}

const handleResetValidation = () => {
	resetValidation.value = false;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(HoModalDialog, {
    headerText: connectionsDialogLabel.value,
    okButtonText: __props.loading.connect ? _unref(localization).t('common.connecting') : _unref(localization).t('common.connect'),
    cancelButtonText: _unref(localization).t('common.close'),
    disableOk: disableConnectButton.value,
    disableCancel: showEditForm.value || __props.hideCloseButton,
    hideOk: showEditForm.value,
    hideCancel: showEditForm.value || __props.hideCloseButton,
    hideFooter: showEditForm.value,
    width: 640,
    allowKeyPress: true,
    onOnOk: handleModalOk,
    onOnCancel: handleModalCancel
  }, {
    body: _withCtx(() => [
      (showSelectConnection.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("p", null, _toDisplayString(_unref(localization).t('ho-modal-connect.connect-message')), 1),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("label", null, _toDisplayString(_unref(localization).t('ho-modal-connect.my-connections')), 1),
                _createVNode(Autocomplete, {
                  placeholder: _unref(localization).t('connections-dialog.select-connection'),
                  options: options.value,
                  defaultValue: currentConnection.value?.db || '',
                  canClear: false,
                  onOnChange: handleConnectionSelect
                }, null, 8, ["placeholder", "options", "defaultValue"])
              ]),
              _createElementVNode("button", {
                class: "btn h-14 flex mb-4",
                onClick: handleEditModeClick,
                disabled: disableEditButton.value
              }, [
                _createElementVNode("span", _hoisted_5, [
                  _createVNode(_unref(PencilAltIcon), { class: "w-7 h-7" })
                ]),
                _createTextVNode(" " + _toDisplayString(_unref(localization).t('connections-dialog.edit')), 1)
              ], 8, _hoisted_4),
              _createElementVNode("button", {
                class: "btn h-14 flex mb-4",
                onClick: handleAddNewButtonClick
              }, [
                _createElementVNode("span", _hoisted_6, [
                  _createVNode(_unref(PlusIcon), { class: "w-7 h-7" })
                ]),
                _createTextVNode(" " + _toDisplayString(_unref(localization).t('connections-dialog.new')), 1)
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      (showEditForm.value)
        ? (_openBlock(), _createBlock(EditConnectionForm, {
            key: 1,
            loading: __props.loading.connection,
            connection: currentConnection.value || newConnectionTemplate,
            editMode: editMode.value,
            resetValidation: resetValidation.value,
            allConnections: __props.connections,
            onOnEditConnectionClose: handleEditConnectionClose,
            onOnResetValidation: handleResetValidation
          }, null, 8, ["loading", "connection", "editMode", "resetValidation", "allConnections"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["headerText", "okButtonText", "cancelButtonText", "disableOk", "disableCancel", "hideOk", "hideCancel", "hideFooter"]))
}
}

})