import IGridConfig from "@/components/grid/interfaces/iGridConfig";
import IFilterField from "@/interfaces/home/iFilterField";
import IListOptions from "@/interfaces/invoices/iListOptions";
import { InvoiceLedger } from "@/services/invoicesApi";
import { getMonth, getYear, subMonths } from "date-fns";

export const getInitalListOptions = (): IListOptions => {
	const now = new Date();

	const initListOptions: IListOptions = {
		pageIndex: 0,
		pageSize: 20,
		filters: [
			{
				field: "ledger",
				value: InvoiceLedger.SALES,
			},
			{
				field: "month",
				value: getMonth(subMonths(now, 1)),
			},
			{
				field: "year",
				value: getYear(subMonths(now, 1)),
			},
			{
				field: "includeCancelled",
				value: false,
			},
		],
	};

	return initListOptions;
};

export const getInitalLedgerOptions = (
	translate: (val: string) => string
): {
	label: string;
	value: string;
}[] => {
	return [
		{
			label: translate("home.sales"),
			value: "sales",
		},
		{
			label: translate("home.purchases"),
			value: "purchases",
		},
	];
};

export const getInitFilterFields = (
	translate: (val: string) => string
): IFilterField[] => [
	{
		name: "ledger",
		placeholderText: translate("home.ledger-placeholder"),
		initialValue: "sales",
		canClear: false,
		options: getInitalLedgerOptions(translate),
		type: "select",
	},
	{
		name: "date",
		type: "dateRange",
	},
	{
		name: "company",
		initialValue: null,
		placeholderText: translate("home.company-placeholder"),
		options: [],
		canClear: false,
		type: "select",
	},
];

export const getInitialRowKeysConfig = (
	translate: (val: string) => string
): {
	key: string;
	label?: string;
	class?: string;
	width?: number | string;
	minWidth?: number;
}[] => {
	return [
		{
			key: "nraDocType",
			width: 120,
			minWidth: 120,
			label: translate("grid.labels.nra-doc-type"),
		},
		{
			key: "number",
			width: 120,
			minWidth: 120,
			label: translate("grid.labels.number"),
		},
		{
			key: "invoiceDate",
			width: 140,
			minWidth: 140,
			label: translate("grid.labels.invoice-date"),
		},
		{
			key: "date",
			width: 140,
			minWidth: 140,
			label: translate("grid.labels.date"),
		},
		{
			key: "customerVat",
			width: 160,
			minWidth: 160,
			label: translate("grid.labels.customer-vat"),
		},
		{
			key: "customer",
			width: "10%",
			minWidth: 200,
			label: translate("grid.labels.customer"),
		},
		{
			key: "customerCountry",
			width: 100,
			minWidth: 100,
			label: translate("grid.labels.customer-country"),
		},
		{
			key: "taxBase",
			width: 100,
			minWidth: 100,
			label: translate("grid.labels.tax-base"),
			class: "text-right ho-cell-number",
		},
		{
			key: "tax",
			width: 100,
			minWidth: 100,
			label: translate("grid.labels.tax"),
			class: "text-right ho-cell-number",
		},
		{
			key: "status",
			width: 80,
			minWidth: 80,
			label: translate("grid.labels.status"),
		},
	];
};

export const gridConfig: IGridConfig = {
	sortFields: [
		{
			field: "invoiceDate",
		},
		{
			field: "date",
		},
		{
			field: "customer",
		},
		{
			field: "number",
		},
	],
};
