import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelDynamic as _vModelDynamic, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["autocomplete", "aria-autocomplete", "type", "disabled"]
const _hoisted_2 = {
  key: 0,
  class: "text-red-500"
}

import { IValidator, validate } from '@/utils/validator';
import { computed, ref, watch, WritableComputedRef, Ref } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'ho-text',
  props: {
	label: String,
	modelValue: [String, Number],
	modelKey: String,
	errors: String,
	placeholder: String,
	validation: {
		type: Array as () => IValidator[]
	},
	disableInput: {
		type: Boolean,
		default: false
	},
	resetValidation: {
		type: Boolean,
		default: false
	},
	isMasked: {
		type: Boolean,
		default: false
	},
	disableAutocomplete: {
		type: Boolean,
		default: false
	}
},
  emits: ['update:modelValue', 'onValidStateChange', 'onResetValidation'],
  setup(__props, { emit: __emit }) {

const props = __props;
const emit = __emit;

const errorMessage: Ref<string | null> = ref(null);

watch(()=> props.resetValidation, (current)=>{
	if(current === true){
		errorMessage.value = null;
		emit('onResetValidation');
	}
});

const value: WritableComputedRef<string | number> = computed({
	get: () => {
		return props.modelValue || "";
	},
	set: (value) => {
		const errors = validate(value, props.validation as IValidator[]);
		const identifier = props.modelKey ? props.modelKey : props.label;

		if (errors && errors.length > 0) {
			errorMessage.value = errors.join(", ");
			emit('onValidStateChange', identifier, false);
		} else {
			errorMessage.value = null;
			emit('onValidStateChange', identifier, true);
		}
		emit('update:modelValue', value);
	}
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("label", null, _toDisplayString(__props.label), 1),
    _withDirectives(_createElementVNode("input", {
      class: "bg-white",
      autocomplete: __props.disableAutocomplete ? 'new-password' : 'on',
      "aria-autocomplete": __props.disableAutocomplete ? 'none' : 'both',
      type: __props.isMasked ? 'password' : 'text',
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((value).value = $event)),
      disabled: __props.disableInput
    }, null, 8, _hoisted_1), [
      [_vModelDynamic, value.value]
    ]),
    (errorMessage.value)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(errorMessage.value), 1))
      : _createCommentVNode("", true)
  ], 64))
}
}

})