<template>
	<div class="home-wrapper" :class="{ initial: !firstLoadTriggered }">
		<loading-spinner v-if="loading.companies" />
		<div class="home">
			<div class="home-head flex" v-if="!firstLoadTriggered">
				<div class="top-bar">
					<div class="user" v-if="connection">
						{{ localization.t("home.filter-message") }}
						<div class="btn" @click="handleImportModalOpen">
							{{ localization.t("home.import-convert") }}
						</div>
						{{ localization.t("home.vat-file") }}
					</div>
					<div class="user" v-if="!connection">
						{{ localization.t("app.no-connection") }}.
						<div class="btn" @click="emit('onOpenConnections')">
							{{ localization.t("ho-modal-connect.select-connection") }}
						</div>
					</div>
				</div>
			</div>

			<div class="home-grid" v-if="connection && connection.active">
				<div class="filters-container">
					<home-filters
						:loading="loading"
						:filterFields="filterFieldsConfig"
						:filters="listOptions.filters"
						@onFiltersChange="handleChangeFilters"
						@onApplyFilters="handleApplyFilters"
					/>
				</div>

				<div class="grid-container">
					<grid
						v-if="firstLoadTriggered"
						:loading="loading"
						:config="gridConfig"
						:data="data.data"
						:total="data.total"
						:listOptions="listOptions"
						:hasDataErrors="dataHasInvalidFields"
						:isSelectAllMode="isSelectAllMode"
						:selections="selections"
						:rowKeysConfig="initRowKeysConfig"
						@onChangePage="handleChangePage"
						@onChangeFilters="handleChangeFilters"
						@onChangeSorting="handleChangeSorting"
						@onChangeSelection="handleChangeSelection"
						@onChangeSelectAllMode="handleChangeSelectAllMode"
						@onResetSelection="handleResetSelections"
					>
						<template v-slot:topBarPlaceholder>
							<export-actions
								:loading="loading.export"
								:hasDataErrors="dataHasInvalidFields"
								:exportButtonsDisabled="exportButtonsDisabled"
								:exportViesButtonDisabled="exportViesButtonDisabled"
								@onExportClick="handleExportData"
								@onExportViesClick="handleViesModalOpen"
							/>
						</template>
						<template v-slot:emptyGridResults>
							<p>{{ localization.t("grid.export-empty-results") }}</p>
							<export-actions
								:loading="loading.export"
								:hasDataErrors="dataHasInvalidFields"
								:exportButtonsDisabled="exportButtonsDisabled"
								:exportViesVisible="false"
								@onExportClick="handleExportData"
							/>
						</template>
					</grid>
				</div>
			</div>

			<export-vies-declaration
				v-if="viesModalOpen"
				:profileData="profileData"
				@onExportModalClose="handleViesModalClose"
			/>
		</div>
	</div>
</template>

<script setup lang="ts">
import { computed, reactive, ref, toRaw, watch, Ref } from "vue";
import { useI18n } from "vue-i18n";
import { ApiError } from "@/types/errorTypes";
import { getPaged, exportData } from "../../services/invoicesApi";
import HomeFilters from "@/components/home/filters.vue";
import ExportViesDeclaration from "@/components/home/export-vies-declaration.vue";
import ExportActions from "@/components/home/export-actions.vue";
import grid from "@/components/grid/grid.vue";
import IListOptions from "@/interfaces/invoices/iListOptions";
import IListFilter from "@/interfaces/invoices/iListFilter";
import IFilterField from "@/interfaces/home/iFilterField";
import IProfile from "@/interfaces/users/iProfile";
import IDeclarantExtended from "@/interfaces/users/iDeclarantExtended";
import IExportType from "@/interfaces/invoices/iExportType";
import IUser from "@/interfaces/users/iUser";
import { getSession } from "@/services/authService";
import LoadingSpinner from "@/components/loading-spinner.vue";
import router from "@/router";
import ICompany from "@/interfaces/companies/iCompany";
import IConnection from "@/interfaces/users/iConnection";
import {
	getInitalLedgerOptions,
	getInitalListOptions,
	getInitFilterFields,
	getInitialRowKeysConfig,
	gridConfig,
} from "./config";
import { validateInvoicesRequiredFields } from "@/utils/customValidators/invoicesValidators";
import { errorToast } from "@/utils/toastr";

const localization = useI18n();

type LoadingFields =
	| "grid"
	| "export"
	| "companies"
	| "import"
	| "user"
	| "connection";

const invoicesValidateRequiredFields = [
	"customerVat",
	"customerCountry",
	"nraDocType",
	"number",
];

const props = defineProps({
	user: {
		type: Object as () => IUser,
	},
	companies: {
		type: Array as () => ICompany[],
	},
	connection: {
		type: Object as () => IConnection,
	},
});

const emit = defineEmits([
	"onImportModalOpen",
	"onFirstFilterTriggered",
	"onOpenConnections",
]);

const companies = ref(
	[] as {
		label?: string;
		value: number;
	}[]
);

const data = ref({
	data: [],
	total: 0,
});

const filterFieldsConfig: Ref<IFilterField[]> = ref(
	getInitFilterFields(localization.t)
);

const loading = ref({
	grid: false,
	export: false,
	companies: false,
	import: false,
	user: false,
	connection: false,
});

const viesModalOpen = ref(false);
const firstLoadTriggered = ref(false);
const dataHasInvalidFields = ref(false);

const selections = ref({
	itemIds: [] as string[],
	excludedItemIds: [] as string[],
});

const isSelectAllMode = ref(false);
const initRowKeysConfig = ref(getInitialRowKeysConfig(localization.t));

const initalListOptions = getInitalListOptions();

watch(
	() => localization.locale.value,
	() => {
		initRowKeysConfig.value = getInitialRowKeysConfig(localization.t);

		filterFieldsConfig.value.forEach((filter) => {
			if (filter.name === "ledger") {
				filter.options = getInitalLedgerOptions(localization.t);
			}
		});
	}
);

const profileData = computed(() => {
	return toRaw({ ...(props.user || {}).profileData } as IProfile &
		IDeclarantExtended);
});

const exportButtonsDisabled = computed(() => {
	return (
		loading.value["export"] ||
		loading.value["grid"] ||
		dataHasInvalidFields.value
	);
});

const exportViesButtonDisabled = computed(() => {
	let isPurchasesOn = false;
	const foundFilter = listOptions.filters?.find((f) => f.field === "ledger");
	if (foundFilter && foundFilter.value === "purchases") {
		isPurchasesOn = true;
	}

	return isPurchasesOn;
});

const userDataUnwatch = watch(
	() => props.user,
	(newUserData, oldUserData) => {
		const sessionData = getSession();
		const hasSession = sessionData && !Number.isNaN(sessionData.session_id);

		if (!oldUserData?.userName && newUserData?.userName) {
			if (hasSession) {
				userDataUnwatch();
			}
		}
	}
);

const companiesDataUnwatch = watch(
	() => props.companies,
	(newData, oldData) => {
		if ((!oldData || oldData.length === 0) && newData && newData.length > 0) {
			const companiesFilter = filterFieldsConfig.value.find(
				(f) => f.name === "company"
			);

			if (companiesFilter) {
				companiesFilter.options = (newData || []).map((c) => {
					return {
						label: c.name,
						value: c.id,
					};
				});

				companies.value = companiesFilter.options;

				(listOptions.filters || []).push({
					field: "company",
					value: companiesFilter.options[0].value,
				});

				companiesFilter.initialValue =
					companiesFilter.options.length === 1
						? companiesFilter.options[0].value
						: null;
			}

			companiesDataUnwatch();
		}
	}
);

const listOptions: IListOptions = reactive(initalListOptions);

const handleChangeSelection = (newSelections: string[]) => {
	if (!isSelectAllMode.value) {
		selections.value.itemIds = newSelections;
	} else {
		selections.value.excludedItemIds = newSelections;
	}
};

const handleResetSelections = () => {
	selections.value.itemIds = [];
	selections.value.excludedItemIds = [];
};

const handleChangeSelectAllMode = (newMode: boolean) => {
	isSelectAllMode.value = newMode;
};

const handleChangePage = async (page: number) => {
	listOptions.pageIndex = page;

	try {
		const resultData = await executeAsync("grid", async () => {
			return await getPaged(listOptions);
		});

		const hasInvalidFields = validateInvoicesRequiredFields(
			resultData.data,
			invoicesValidateRequiredFields
		);
		if (!dataHasInvalidFields.value && hasInvalidFields) {
			dataHasInvalidFields.value = hasInvalidFields;
		}

		data.value = resultData;
	} catch (error) {
		const err = error as ApiError;
		errorToast(err, err.message);
	}
};

const handleChangeFilters = async (filters?: IListFilter[]) => {
	console.log("aa");
	(filters || []).forEach((filter) => {
		if (!listOptions.filters) {
			listOptions.filters = [];
		}

		const foundFilter = listOptions.filters.find(
			(f) => f.field === filter.field
		);
		if (foundFilter) {
			foundFilter.value = filter.value;
		} else {
			const newFilter: IListFilter = {
				field: filter.field,
				value: filter.value,
			};

			listOptions.filters?.push(newFilter);
		}
	});
	listOptions.pageIndex = 0;
	listOptions.sort = undefined;
};

const handleChangeSorting = async (
	field: string,
	direction: "asc" | "desc"
) => {
	listOptions.sort = {
		field,
		direction,
	};
	listOptions.pageIndex = 0;

	try {
		data.value = await executeAsync("grid", async () => {
			return await getPaged(listOptions);
		});
	} catch (error) {
		const err = error as ApiError;
		errorToast(err, err.message);
	}
};

const handleExportData = async (
	exportType: IExportType,
	declarantData?: IProfile & IDeclarantExtended
) => {
	const exportItemIds = isSelectAllMode.value
		? selections.value.excludedItemIds
		: selections.value.itemIds;

	try {
		await executeAsync("export", async () => {
			return exportData(
				exportItemIds,
				isSelectAllMode.value,
				exportType,
				listOptions,
				declarantData
			);
		});
	} catch (error) {
		const err = error as ApiError;
		errorToast(err, err.message);
	}
};

const handleApplyFilters = async () => {
	listOptions.pageIndex = 0;
	listOptions.sort = undefined;

	firstLoadTriggered.value = true;
	emit("onFirstFilterTriggered");

	if (dataHasInvalidFields.value !== false) {
		dataHasInvalidFields.value = false;
	}

	try {
		const resultData = await executeAsync("grid", async () => {
			return await getPaged(listOptions);
		});

		const hasInvalidFields = validateInvoicesRequiredFields(
			resultData.data,
			invoicesValidateRequiredFields
		);
		if (hasInvalidFields) {
			dataHasInvalidFields.value = hasInvalidFields;
		}

		data.value = resultData;
	} catch (error) {
		const err = error as ApiError;
		errorToast(err, err.message);
	}
};

const handleViesModalOpen = () => {
	viesModalOpen.value = true;
};

const handleViesModalClose = (
	declarantData?: IProfile & IDeclarantExtended
) => {
	if (declarantData) {
		const exportType: IExportType = {
			fileType: "TXT",
			includeVies: true,
		};
		handleExportData(exportType, declarantData);
	}
	viesModalOpen.value = !viesModalOpen.value;
};

const handleImportModalOpen = () => {
	emit("onImportModalOpen");
};

// TODO extract in HoC:
const executeAsync = async (
	loadingField: LoadingFields,
	exec: any,
	options?: { [key: string]: string; value: string }
) => {
	loading.value[loadingField] = true;
	const showToast = options?.showToast || true;
	const rethrowError = options?.rethrowError || true;

	try {
		const result = await exec();
		loading.value[loadingField] = false;
		return result;
	} catch (error) {
		const err = error as ApiError;
		if (err.statusCode === 401) {
			router.push("/login");
		} else {
			if (showToast) {
				const err = error as ApiError;
				if (process.env.NODE_ENV === "production") {
					if (err.apiCode === 150) {
						console.log(err);
						errorToast(err, err.innerMessage);
					}
				} else {
					console.log(err);
					errorToast(err, err.innerMessage);
				}
			}

			if (rethrowError) {
				throw error;
			}
		}
	} finally {
		loading.value[loadingField] = false;
	}
};
</script>

<style lang="postcss">
.home-wrapper {
	height: 100%;
}

.home-wrapper.initial {
	display: flex;
	align-items: center;
	justify-content: center;
}

.home-wrapper.initial .home {
	height: auto;
	transition: height 0.5s ease-out;
}

.top-bar {
	padding: 16px 24px 8px;
	font-size: 1.8rem;
	font-weight: 400;
}

.top-bar strong {
	font-weight: 800;
}

.home {
	height: 100%;
}

.home-head {
	height: 60px;
	font-size: 1.6rem;
}
.home-head .btn {
	font-size: 1.4rem;
	padding: 4px 8px;
}

.home-grid {
	height: 100%;
	padding: 16px;
}

.grid-container {
	position: relative;
	z-index: 0;
	height: 100%;
}
.filters-container {
	position: relative;
	z-index: 1;
}

@media (max-height: 767px) {
	.home-wrapper.initial {
		position: absolute;
		width: 100%;
	}

	.home-grid {
		height: 100%;
		padding: 8px;
	}
}
</style>
