import IListFilter from "@/interfaces/invoices/iListFilter";
import IListOptions from "@/interfaces/invoices/iListOptions";
import ISortOptions from "@/interfaces/invoices/iSortOptions";
import { formatToApi } from "@/utils/dateTime";
import { endOfMonth, startOfMonth } from "date-fns";

const addParamsToUrl = (
	url: string,
	keyValue: { key: string; value?: string | boolean }[]
) => {
	let mappedUrl = url;

	keyValue.forEach((kv) =>
		kv.value !== null || kv.value !== undefined
			? (mappedUrl = `${mappedUrl}&${kv.key}=${kv.value}`)
			: `${mappedUrl}&${kv.key}`
	);

	return mappedUrl;
};

export const buildInvoicesGetListUrlQueryParams = (
	options?: IListOptions
): string => {
	let pSize = 10;
	let pIndex = 0;
	if (options) {
		const { pageIndex, pageSize } = options;
		pIndex = pageIndex;
		pSize = pageSize;
	}

	let urlQueryParams = `${ApiFilterFields.PAGE_INDEX}=${pIndex}&${ApiFilterFields.PAGE_SIZE}=${pSize}`;

	if (options?.filters && options?.filters.length > 0) {
		const parsedFilters = options.filters.filter(
			(f) => f.value !== null || f.value !== undefined
		);
		urlQueryParams = urlAddInvoiceFilters(urlQueryParams, parsedFilters);
	}

	if (options?.sort && options.sort.field.length > 0) {
		urlQueryParams = urlAddInvoiceSorting(urlQueryParams, options.sort);
	}

	return urlQueryParams;
};

enum ApiFilterFields {
	LEDGER = "ledger",
	DATE_FROM = "datefrom",
	DATE_UNTIL = "dateuntil",
	COMPANY_ID = "companyid",
	PAGE_INDEX = "pageindex",
	PAGE_SIZE = "pagesize",
	INCLUDE_CANCELLED = "includecancelled",
	WITH_VIES = "withvies",
}

export const mapFiltersDatePeriodParams = (
	filters: IListFilter[]
): { [key: string]: string } | null => {
	const selectedMonth = filters.find((f) => f.field === "month");
	if (filters.find((f) => f.field === "month")) {
		const selectedYear = filters.find((f) => f.field === "year");

		if (
			selectedYear &&
			!isNaN(Number(selectedYear.value)) &&
			!isNaN(Number(selectedMonth?.value))
		) {
			const now = new Date();
			now.setMonth(Number(selectedMonth?.value));
			now.setFullYear(Number(selectedYear.value));

			const startDate = startOfMonth(now);
			const endDate = endOfMonth(now);

			return {
				[ApiFilterFields.DATE_FROM]: formatToApi(startDate),
				[ApiFilterFields.DATE_UNTIL]: formatToApi(endDate),
			};
		}
	}

	return null;
};

export const urlAddInvoiceFilters = (
	url: string,
	filters: IListFilter[]
): string => {
	const mappedFilters: { key: string; value?: string | boolean }[] = [];

	const includeCancelled = filters.find((f) => f.field === "includeCancelled");
	if (includeCancelled && includeCancelled?.value) {
		mappedFilters.push({ key: ApiFilterFields.INCLUDE_CANCELLED, value: true });
	}

	const withVies = filters.find((f) => f.field === "withVies");
	if (withVies && withVies?.value) {
		mappedFilters.push({ key: ApiFilterFields.WITH_VIES, value: true });
	}

	const startEndDates = mapFiltersDatePeriodParams(filters);
	const startEndDateParams: { key: string; value?: string }[] = [];
	startEndDates &&
		Object.entries(startEndDates).forEach(([key, value]) =>
			startEndDateParams.push({
				key,
				value,
			})
		);
	mappedFilters.push(...startEndDateParams);

	const selectedCompany = filters.find((f) => f.field === "company");
	if (selectedCompany) {
		mappedFilters.push({
			key: ApiFilterFields.COMPANY_ID,
			value: selectedCompany.value.toString(),
		});
	}

	const selectedLedger = filters.find((f) => f.field === "ledger");
	if (selectedCompany) {
		mappedFilters.push({
			key: ApiFilterFields.LEDGER,
			value: selectedLedger?.value.toString(),
		});
	}

	url = addParamsToUrl(url, mappedFilters);

	return url;
};

const urlAddInvoiceSorting = (url: string, sort: ISortOptions) => {
	url = `${url}&sort=${sort.field}&direction=${sort.direction}`;
	return url;
};
