<template>
	<ho-modal-dialog
		:headerText="localization.t('import-external-invoices.convert-from-vat')"
		:okButtonText="localization.t('import-external-invoices.upload')"
		:cancelButtonText="state === 'ready' ? localization.t('common.close') : localization.t('common.cancel')"
		:width="720"
		@onOk="handleModalOk"
		@onCancel="handleModalCancel"
		:hideCancel="state === 'loading'"
		:hideOk="state !== 'select'"
		:disableOk="disableImportButton"
	>
		<template v-slot:body>
			<div class="import-form" v-if="state==='select'">
				<p>{{localization.t('import-external-invoices.upload-odoo-declaration')}}</p>

				<ho-form v-model="importFormValues" class="form-gray mt-4" :hideOk="true" :hideCancel="true">
					<template v-slot:body>
						<div class="form-row">
							<h3>{{localization.t('import-external-invoices.labels.submitted-by')}}</h3>
							<div class="flex">
								<div class="form-cell flex-none w-[150px]">
									<ho-text :label="localization.t('common.profile.labels.ucn')" v-model="importFormValues.unifiedCivilNumber" :validation="[{
										type: 'len',
										options: {
											min: 10,
											max: 10
										},
										message: localization.t('common.profile.validators.ucn-digits', { exact: 10 })
									}]" />
								</div>

								<div class="form-cell flex-1">
									<label>{{localization.t('common.profile.labels.full-name')}}</label>
									<input type="text" :placeholder="localization.t('import-external-invoices.placeholders.declarant-name')" v-model="importFormValues.fullName" />
									<p v-if="errors.name" class="text-red-500">{{errors.name}}</p>
								</div>						
							</div>
						</div>

						<div class="form-row">
							<h3>{{localization.t('import-external-invoices.labels.declaration-period')}}</h3>
							<div class="flex">
								<div class="w-96">
									<label>{{localization.t('month-year-selector.period')}}</label>
									<div class="autocomplete-holder inline-block">
										<month-year-selector :default-month="importFormValues.monthIndex" :default-year="importFormValues.year" @onChange="handleDateChange"/>
									</div>
								</div>

								<div class="flex-1 form-cell">
									<label>{{localization.t('import-external-invoices.labels.company')}}</label>
									<div class="autocomplete-holder">
										<Autocomplete
											:placeholder="localization.t('import-external-invoices.placeholders.select-company')"
											:options="companyOptions"
											:defaultValue="importFormValues.companyId"
											:canClear="false"
											@onChange="handleCompanySelect"
										/>
									</div>
								</div>
							</div>
						</div>
						
						<div class="form-row">
							<h3>{{localization.t('import-external-invoices.labels.select-odoo-file')}}</h3>
							<input type="file" name="file" :accept="validFileType" @change="handleUploadFile" @keypress.prevent="" required />
						</div>

						<p v-if="errors.file" class="text-red-500">{{errors.file}}</p>
					</template>
				</ho-form>
			</div>
			<div class="import-loading" v-if="state==='loading'">
				<p class="text-3xl loading-text">{{localization.t('import-external-invoices.converting')}}</p>
			</div>
			<div class="import-success" v-if="state==='ready'">
				<p class="text-center 3xl">
					<check-circle-icon class="w-24 m-auto" /><br />{{localization.t('import-external-invoices.successful-conversion')}}
				</p>
				<p class="text-center">
					<a class="btn" :href="(importResult || {}).downloadUrl" :download="(importResult || {}).fileName">{{localization.t('import-external-invoices.download-txt')}}</a>
				</p>
			</div>
	  </template>
	</ho-modal-dialog>
</template>

<script setup lang="ts">
import { computed, reactive, ref, watch, Ref } from 'vue'
import { useI18n } from 'vue-i18n';
import HoModalDialog from "../modal/ho-modal-dialog.vue";
import Autocomplete from '../form/autocomplete.vue';
import MonthYearSelector from "../form/month-year-selector.vue";
import { CheckCircleIcon } from '@heroicons/vue/outline'
import IConvertDeclarationResult from "@/interfaces/home/iConvertDeclarationResult";
import IDeclarationData from "@/interfaces/home/iDeclarationData";
import { validate } from '@/utils/validator';
import IProfile from '@/interfaces/users/iProfile';
import IDeclarantExtended from '@/interfaces/users/iDeclarantExtended';
import HoForm from "../form/ho-form.vue";
import HoText from "../form/ho-text.vue";
import ICompany from '@/interfaces/companies/iCompany';
import { subMonths } from 'date-fns';

const localization = useI18n();

const VALID_FILE_TYPES = [
	"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", 
	"application/vnd.ms-excel"
];

const validateFile = (file: File) => {
	const errors = [];

	if (file.size > 5 * 1000000) {
		errors.push(localization.t('import-external-invoices.validators.file-size', { max: 5 }));
	}

	if (VALID_FILE_TYPES.indexOf(file.type) === -1) {
		errors.push(localization.t('import-external-invoices.validators.file-type'));
	}

	return errors;
}

type StateType = 'select' | 'loading' | 'ready';

const props = defineProps({
	loading: Boolean,
	importResult: {
		type: Object as () => IConvertDeclarationResult
	},
	companies: {
		type: Array as () => ICompany[]
	},
	declarantData: {
		type: Object as () => IProfile & IDeclarantExtended
	}
});
const emit = defineEmits(['onUploadModalClose']);

const state: Ref<StateType> = ref('select');
const validFileType = ref(VALID_FILE_TYPES.join(","));
const errors: Ref<{[name: string]: string}> = ref({});
const formValid = ref(false);
const validFileSelected = ref(false);

let prefilledFullName = "";
if (props.declarantData) {
	if (props.declarantData.firstName) {
		prefilledFullName = `${props.declarantData.firstName}`;
	}

	if (props.declarantData.middleName) {
		prefilledFullName = `${prefilledFullName} ${props.declarantData.middleName}`;
	}

	if (props.declarantData.lastName) {
		prefilledFullName = `${prefilledFullName} ${props.declarantData.lastName}`;
	}
}

const importFormValues = reactive({
	companyId: props.companies && (props.companies || []).length === 1 ? props.companies[0].id : undefined,
	monthIndex: subMonths(new Date(), 1).getMonth(),
	year:  subMonths(new Date(), 1).getFullYear(),
	fullName: prefilledFullName,
	unifiedCivilNumber: props.declarantData ? props.declarantData.ucn : ""
});

let selectedFile: File | null = null;

const companyOptions = computed(() => {
	return props.companies?.map(c => ({
		label: c.name,
		value: c.id
	}));
});

watch([importFormValues, validFileSelected], () => {
	const hasEmptyProps = (importFormValues.companyId === undefined || importFormValues.companyId === null)
		|| (importFormValues.fullName === null || importFormValues.fullName.trim().length === 0)
		|| (importFormValues.unifiedCivilNumber === null || importFormValues.unifiedCivilNumber.trim().length === 0)
		|| (importFormValues.monthIndex === undefined || importFormValues.monthIndex === null)
		|| (importFormValues.year === undefined || importFormValues.year === null)
		|| selectedFile === null;

	// TODO: Extract validation checking in a form element validation component
	const ucnErrors = validate(importFormValues.unifiedCivilNumber, [{
		type: 'len',
		options: {
			min: 10,
			max: 10,
		},
		message: localization.t('common.profile.validators.ucn-digits', { exact: 10 })
	}]);
	const fullNameErrors = validate(importFormValues.fullName, [{
		type: 'len',
		options: {
			min: 2,
			max: 39,
		},
		message: localization.t('common.profile.validators.full-name-length', { min: 2, max: 39 })
	}]);
	
	if (fullNameErrors.length > 0) {
		errors.value = Object.assign(
			{},
			errors.value,
			{
				['name']: fullNameErrors.join(", ")
			}
		);
	} else {
		delete errors.value['name'];
	}
	if (ucnErrors.length > 0) {
		errors.value = Object.assign(
			{},
			errors.value,
			{
				['ucn']: ucnErrors.join(", ")
			}
		);
	} else {
		delete errors.value['ucn'];
	}
	formValid.value = !hasEmptyProps 
		&& Object.keys(errors.value).length === 0
		&& validFileSelected.value === true;
});

const disableImportButton = computed(() => {
	if (props.loading === true) {
		return true;
	}

	const filledForm = formValid.value === false;
	return filledForm;
});

watch(() => [props.loading, props.importResult], () => {
	const newState: StateType = props.loading 
		? 'loading'
		: props.importResult ? 'ready' : 'select';

	state.value = newState;
});

const handleUploadFile = (ev: any) => {
	const file = ev.target.files[0] as File;
	const fileErrors = validateFile(file);

	if (fileErrors.length > 0) {
		errors.value['file'] = fileErrors.join(", ");
		selectedFile = null;
		validFileSelected.value = false;
	} else {
		delete errors.value['file'];
		selectedFile = file;
		validFileSelected.value = true;
	}
}

const handleCompanySelect = (value: number) => {
	importFormValues.companyId = value;
	handleModalFocusChange();
}

const handleDateChange = (month:number, year:number) => {
	importFormValues.monthIndex = month;
	importFormValues.year = year;
	handleModalFocusChange();
};

const handleModalFocusChange = () => {
	document.getElementById('ho-modal-dialog')?.focus();
}

const handleModalOk = () => {
	const { monthIndex, ...rest } = importFormValues;
	const submitValues: IDeclarationData = {
		...rest,
		month: monthIndex + 1
	};
	emit('onUploadModalClose', selectedFile, {...submitValues});
}

const handleModalCancel = () => {
	emit('onUploadModalClose');
}
</script>

<style lang="postcss" scoped>
.import-form .form-row { @apply mb-4 bg-gray-100 p-4 pt-2 }
.import-form .form-row label { @apply mb-3 block ml-2 }

.import-form .form-row h3 { @apply uppercase ml-2 mb-4 text-gray-500 }

.import-form .form-row .form-cell {
	@apply ml-3
}

.import-form .form-row .form-cell:first-child { @apply ml-0 }

.import-form .form-row .form-cell input[type="text"],
.import-form .form-row .form-cell .autocomplete-holder { 
	width: 100%;
	background: #fff;
	border: none;
}

.import-form .form-row .autocomplete-holder { 
	background: #fff;
	@apply py-2 px-1
}

:deep(.import-form .form-row .autocomplete-holder .ho-autocomplete .multiselect) { 
	border: none;
}

.loading-text:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4,end) 900ms infinite;      
  animation: ellipsis steps(4,end) 900ms infinite;
  content: "\2026"; /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 1.25em;    
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;    
  }
}
</style>