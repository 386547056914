import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, withKeys as _withKeys } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "ho-modal-header ui-test-modal-header"
}
const _hoisted_2 = {
  key: 0,
  class: "ui-test-modal-body"
}
const _hoisted_3 = {
  key: 1,
  class: "ho-modal-footer ui-test-modal-footer"
}
const _hoisted_4 = ["disabled"]
const _hoisted_5 = ["disabled"]

import { onMounted, onBeforeUnmount, ref, Ref } from 'vue'
import { i18n } from '@/i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'ho-modal-dialog',
  props: {
	headerText: String,
	body: {
		type: [String],
		default: () => undefined
	},
	hideFooter: {
		type: Boolean,
		default: false
	},
	hideCancel: {
		type: Boolean,
		default: true
	},
	hideOk: {
		type: Boolean,
		default: false
	},
	okButtonText: {
		type: String,
		default: i18n.global.t('common.ok')
	},
	cancelButtonText: {
		type: String,
		default: i18n.global.t('common.cancel')
	},
	disableOk: {
		type: Boolean,
		default: false
	},
	disableCancel: {
		type: Boolean,
		default: false
	},
	width: Number
},
  emits: ['onOk', 'onCancel'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const modalContainer: Ref<HTMLElement | null> = ref(null);
const wrapperDomRef:Ref<HTMLElement | null> = ref(null);
const scrollableModalOverlay = ref(true);

onMounted(() => {
	setSize();
	document.getElementById('ho-modal-dialog')?.focus();

	addModalBodySizeListener();
});

onBeforeUnmount(() => window.removeEventListener('resize', setSize));

const addModalBodySizeListener = () => {
	window.addEventListener('resize', setSize);

	const modalBody = wrapperDomRef.value?.getElementsByClassName('ho-modal-body')[0];
	
	if (window.ResizeObserver) {
		const resizeObserver = new ResizeObserver(() => setSize());
		modalBody && resizeObserver.observe(modalBody);
	}
};

const setSize = () => {
		const windowObj:Window = window;
		const windowHeight = windowObj.innerHeight;
		const wrapperHeight = wrapperDomRef.value?.clientHeight;

		const wrapperHeightLarger = wrapperHeight && wrapperHeight > windowHeight;

		if (scrollableModalOverlay.value === false && (wrapperHeightLarger)) {
			scrollableModalOverlay.value = true;
		} else if (scrollableModalOverlay.value === true && (!wrapperHeightLarger)) {
			scrollableModalOverlay.value = false;
		}
};

const getBodyStyle = () => {
	return { 
		maxWidth: `${props.width || 400}px`
	}
}

const handleOk = () => {
	emit('onOk');
}

const handleCancel = () => {
	emit('onCancel');
}

const handleKeyUp = (event : KeyboardEvent) =>{
	if (event.key == 'Enter' && !props.disableOk) {
		handleOk();
	} else if (event.key == 'Escape' && !props.disableCancel) {
		handleCancel();
	}
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["ho-modal-overlay ui-test-modal-dialog", {'scrollable': scrollableModalOverlay.value}]),
    id: "ho-modal-dialog",
    onKeyup: [
      _withKeys(handleKeyUp, ["enter"]),
      _withKeys(handleKeyUp, ["esc"])
    ],
    tabindex: "-1"
  }, [
    _createElementVNode("div", {
      class: "ho-modal-wrapper",
      ref_key: "wrapperDomRef",
      ref: wrapperDomRef
    }, [
      _createElementVNode("div", {
        class: "ho-modal-container ui-test-modal-container",
        style: _normalizeStyle(getBodyStyle()),
        ref_key: "modalContainer",
        ref: modalContainer
      }, [
        (__props.headerText && __props.headerText.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(__props.headerText), 1))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass(["ho-modal-body", { 'hide-footer': __props.hideFooter }])
        }, [
          _renderSlot(_ctx.$slots, "body", {}, () => [
            (__props.body)
              ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(__props.body), 1))
              : _createCommentVNode("", true)
          ])
        ], 2),
        (!__props.hideFooter)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _renderSlot(_ctx.$slots, "footer", {}, () => [
                (!__props.hideFooter ? !__props.hideOk : false)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      class: "btn ui-test-ok-button",
                      onClick: handleOk,
                      disabled: __props.disableOk
                    }, _toDisplayString(__props.okButtonText), 9, _hoisted_4))
                  : _createCommentVNode("", true),
                (!__props.hideFooter ? !__props.hideCancel : false)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 1,
                      class: "btn btn-outline ui-test-cancel-button",
                      onClick: handleCancel,
                      disabled: __props.disableCancel
                    }, _toDisplayString(__props.cancelButtonText), 9, _hoisted_5))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true)
      ], 4)
    ], 512)
  ], 34))
}
}

})