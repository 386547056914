import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "form-row" }
const _hoisted_2 = { class: "form-cell flex-none" }
const _hoisted_3 = { class: "form-cell flex-none" }
const _hoisted_4 = { class: "form-cell flex-none" }
const _hoisted_5 = {
  key: 0,
  class: "text-red-500"
}

import { computed, ref, watch, Ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { getValidators } from './validations';
import HoForm from "../../form/ho-form.vue";
import HoText from "../../form/ho-text.vue";

type ValidationField = 'oldPassword' | 'newPassword' | 'confirmPassword';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
	loading: Boolean
},
  emits: ['onChangePasswordClose'],
  setup(__props, { emit: __emit }) {

const localization = useI18n();

const validators = getValidators(localization.t);


const emit = __emit;

const formData = ref({
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
});

const validState = ref({
	oldPassword: false,
	newPassword: false,
	confirmPassword: false
});
const passwordsNotMatchMessage: Ref<string | null> = ref(null);

const isValid = computed(() => {
	return validState.value.oldPassword
		&& validState.value.newPassword
		&& validState.value.confirmPassword
		&& formData.value.newPassword === formData.value.confirmPassword
});

watch(() => [formData.value.confirmPassword, formData.value.newPassword], () => {
	if (formData.value.confirmPassword.localeCompare(formData.value.newPassword) && formData.value.confirmPassword !== '') {
		passwordsNotMatchMessage.value = localization.t('user-data-dialog.passwords-mismatch')
	} else {
		passwordsNotMatchMessage.value = null;
	}
})

const handleChangeCancel = () => {
	emit('onChangePasswordClose');
};

const handleChangeSave = () => {
	emit('onChangePasswordClose', formData.value.oldPassword, formData.value.newPassword);
};

const handleValidationStateChange = (fieldKey: ValidationField, value: boolean) => {
	validState.value[fieldKey] = value;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(HoForm, {
    class: "form-gray mt-4",
    modelValue: formData.value,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((formData).value = $event)),
    onOnOk: handleChangeSave,
    onOnCancel: handleChangeCancel,
    okButtonText: __props.loading ? _unref(localization).t('user-data-dialog.changing') : _unref(localization).t('user-data-dialog.change'),
    cancelButtonText: _unref(localization).t('common.cancel'),
    disableOk: !isValid.value || __props.loading
  }, {
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(HoText, {
            label: _unref(localization).t('user-data-dialog.old-password'),
            modelValue: formData.value.oldPassword,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((formData.value.oldPassword) = $event)),
            isMasked: true,
            validation: _unref(validators).oldPassword,
            modelKey: "oldPassword",
            onOnValidStateChange: handleValidationStateChange
          }, null, 8, ["label", "modelValue", "validation"])
        ]),
        _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1)),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(HoText, {
            label: _unref(localization).t('user-data-dialog.new-password'),
            modelValue: formData.value.newPassword,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((formData.value.newPassword) = $event)),
            isMasked: true,
            validation: _unref(validators).newPassword,
            modelKey: "newPassword",
            onOnValidStateChange: handleValidationStateChange
          }, null, 8, ["label", "modelValue", "validation"])
        ]),
        _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(HoText, {
            label: _unref(localization).t('user-data-dialog.confirm'),
            modelValue: formData.value.confirmPassword,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((formData.value.confirmPassword) = $event)),
            isMasked: true,
            validation: _unref(validators).confirmPassword,
            modelKey: "confirmPassword",
            onOnValidStateChange: handleValidationStateChange
          }, null, 8, ["label", "modelValue", "validation"]),
          (passwordsNotMatchMessage.value)
            ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(passwordsNotMatchMessage.value), 1))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "okButtonText", "cancelButtonText", "disableOk"]))
}
}

})