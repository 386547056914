import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["id", "checked"]
const _hoisted_3 = { class: "ml-2 text-gray-900 text-2xl uppercase ui-test-toggle-label" }

import { ref } from 'vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'toggle',
  props: {
	label: String,
	name: String,
	checked: Boolean
},
  emits: ['onChange'],
  setup(__props, { emit: __emit }) {

const props = __props;
const emit = __emit;

const isChecked = ref(props.checked);
const onCheckboxChange = () => {
	isChecked.value = !isChecked.value;
	emit("onChange", isChecked.value);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("label", {
    for: `ho-toggle-${__props.name}`,
    class: "flex items-center cursor-pointer relative ho-toggle-component"
  }, [
    _createElementVNode("input", {
      type: "checkbox",
      class: "sr-only ui-test-toggle-input",
      id: `ho-toggle-${__props.name}`,
      checked: isChecked.value,
      onChange: _cache[0] || (_cache[0] = ($event: any) => (onCheckboxChange()))
    }, null, 40, _hoisted_2),
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "toggle-bg bg-peri-200 rounded-full" }, null, -1)),
    _createElementVNode("span", _hoisted_3, _toDisplayString(__props.label), 1)
  ], 8, _hoisted_1))
}
}

})