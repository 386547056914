import { defineComponent as _defineComponent } from 'vue'
import { vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, unref as _unref, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "ho-head-checkbox ho-cell-static w-18 px-8 ui-test-checkbox-cell"
}

import { ref, watch } from "vue";
import GridHeadCell from "./grid-head-cell.vue";
import IGridHeadCellConfig from "./interfaces/iGridHeadCellConfig";
import { getCellWidthStyle } from "./shared/gridUtils";
import ISortOptions from "../../interfaces/invoices/iSortOptions";


export default /*@__PURE__*/_defineComponent({
  __name: 'grid-head',
  props: {
	loading: Boolean,
	columnsConfig: {
		type: Array as () => IGridHeadCellConfig[],
	},
	selectAllMode: Boolean,
	hasSelectAll: {
		type: Boolean,
		default: () => false,
	},
	selectable: {
		type: Boolean,
		default: () => false,
	},
	sortOptions: {
		type: Object as () => ISortOptions,
	},
},
  emits: ["onSortingChange", "onSelectAllChange"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const isSelectAllMode = ref(props.selectAllMode);

const handleSortingChange = (field: string, value: "asc" | "desc") => {
	emit("onSortingChange", field, value);
};

const handleSelectAllCheck = (ev: any) => {
	emit("onSelectAllChange", ev.target.checked);
};

watch(
	() => props.selectAllMode,
	(newValue) => {
		isSelectAllMode.value = newValue;
	}
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("thead", null, [
    _createElementVNode("tr", null, [
      (__props.selectable)
        ? (_openBlock(), _createElementBlock("th", _hoisted_1, [
            (__props.hasSelectAll)
              ? _withDirectives((_openBlock(), _createElementBlock("input", {
                  key: 0,
                  type: "checkbox",
                  name: "select-all",
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((isSelectAllMode).value = $event)),
                  onChange: handleSelectAllCheck
                }, null, 544)), [
                  [_vModelCheckbox, isSelectAllMode.value]
                ])
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.columnsConfig, (item, i) => {
        return (_openBlock(), _createBlock(GridHeadCell, {
          key: i,
          item: item,
          loading: __props.loading,
          sortOptions: __props.sortOptions,
          style: _normalizeStyle(_unref(getCellWidthStyle)(item.width, item.minWidth)),
          class: _normalizeClass(item.class),
          onOnSortingChange: handleSortingChange
        }, null, 8, ["item", "loading", "sortOptions", "style", "class"]))
      }), 128))
    ])
  ]))
}
}

})