<template>
	<ho-form
		v-model="formData"
		class="form-gray mt-4"
		:okButtonText="okButtonText || localization.t('common.update')"
		:cancelButtonText="localization.t('common.cancel')"
		:disableOk="!isValid"
		@onOk="handleModalOk"
		@onCancel="handleModalCancel"
	>
		<template v-slot:body>
			<div class="form-row">
				<h3 v-if="formLabel">{{ formLabel }}</h3>

				<div class="flex mt-4">
					<div class="form-cell flex-1">
						<ho-text
							:label="localization.t('common.profile.labels.first-name')"
							v-model="formData.firstName"
							modelKey="firstName"
							:validation="validators.firstName"
							@onValidStateChange="handleValidationStateChange"
						/>
					</div>
					<div class="form-cell flex-1">
						<ho-text
							:label="localization.t('common.profile.labels.middle-name')"
							v-model="formData.middleName"
							modelKey="middleName"
							:validation="validators.middleName"
							@onValidStateChange="handleValidationStateChange"
						/>
					</div>
					<div class="form-cell flex-1">
						<ho-text
							:label="localization.t('common.profile.labels.last-name')"
							v-model="formData.lastName"
							modelKey="lastName"
							:validation="validators.lastName"
							@onValidStateChange="handleValidationStateChange"
						/>
					</div>
				</div>

				<div class="flex mt-8">
					<div class="form-cell flex-none w-[190px]">
						<ho-text
							:label="localization.t('common.profile.labels.ucn')"
							v-model="formData.ucn"
							modelKey="ucn"
							:validation="validators.ucn"
							@onValidStateChange="handleValidationStateChange"
						/>
					</div>

					<div class="form-cell flex-none w-[190px]">
						<ho-select
							:label="localization.t('common.profile.labels.declarant-type')"
							v-model="formData.declarantType"
							modelKey="declarantType"
							:select-options-list="declarantOptions"
							@onValidStateChange="handleValidationStateChange"
						/>
					</div>
				</div>

				<div class="flex mt-8">
					<div class="form-cell flex-none w-[190px]">
						<ho-text
							:label="localization.t('common.profile.labels.city')"
							v-model="formData.city"
							modelKey="city"
							:validation="validators.city"
							@onValidStateChange="handleValidationStateChange"
						/>
					</div>

					<div class="form-cell flex-none w-[100px]">
						<ho-text
							:label="localization.t('common.profile.labels.postal-code')"
							v-model="formData.postalCode"
							modelKey="postalCode"
							:validation="validators.postalCode"
							@onValidStateChange="handleValidationStateChange"
						/>
					</div>
				</div>

				<div class="flex mt-8 mb-4">
					<div class="form-cell flex-auto">
						<ho-text
							:label="localization.t('common.profile.labels.address')"
							v-model="formData.address"
							modelKey="address"
							:validation="validators.address"
							@onValidStateChange="handleValidationStateChange"
						/>
					</div>
				</div>
			</div>
		</template>
	</ho-form>
</template>

<script setup lang="ts">
import { computed, ref, Ref } from "vue";
import { useI18n } from "vue-i18n";
import { getDeclarantValidators, ValidationField } from "./validations";
import HoForm from "../../form/ho-form.vue";
import HoText from "../../form/ho-text.vue";
import HoSelect from "../../form/ho-select.vue";
import IProfile from "@/interfaces/users/iProfile";
import IDeclarantExtended from "@/interfaces/users/iDeclarantExtended";

const localization = useI18n();

const props = defineProps({
	declarantData: {
		type: Object as () => IProfile & IDeclarantExtended,
	},
	formLabel: {
		type: String,
	},
	okButtonText: {
		type: String,
	},
});
const declarantOptions = [
	{
		label: localization.t("declarant-data-dialog.declarant-representative"),
		value: "R",
	},
	{
		label: localization.t("declarant-data-dialog.declarant-attorney"),
		value: "A",
	},
];
const emit = defineEmits(["onClose"]);

const validators = getDeclarantValidators(localization.t);

const formData = ref({ ...props.declarantData });

const validState: Ref<{ [key: string]: boolean }> = ref({
	firstName: formData.value.firstName ? true : false,
	middleName: formData.value.middleName ? true : false,
	lastName: formData.value.lastName ? true : false,
	ucn: formData.value.ucn ? true : false,
	postalCode: formData.value.postalCode ? true : false,
	address: formData.value.address ? true : false,
	city: formData.value.city ? true : false,
	declarantType: formData.value.declarantType ? true : false,
});

const isValid = computed(() => {
	return (
		validState.value.firstName &&
		validState.value.middleName &&
		validState.value.lastName &&
		validState.value.ucn &&
		validState.value.city &&
		validState.value.address &&
		validState.value.lastName &&
		validState.value.postalCode &&
		validState.value.declarantType
	);
});

const handleModalCancel = () => {
	emit("onClose");
};

const handleModalOk = () => {
	emit("onClose", formData.value);
};

const handleValidationStateChange = (
	fieldKey: ValidationField,
	value: boolean
) => {
	validState.value[fieldKey] = value;
};
</script>
