import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "home" }
const _hoisted_2 = {
  key: 0,
  class: "home-head flex"
}
const _hoisted_3 = { class: "top-bar" }
const _hoisted_4 = {
  key: 0,
  class: "user"
}
const _hoisted_5 = {
  key: 1,
  class: "user"
}
const _hoisted_6 = {
  key: 1,
  class: "home-grid"
}
const _hoisted_7 = { class: "filters-container" }
const _hoisted_8 = { class: "grid-container" }

import { computed, reactive, ref, toRaw, watch, Ref } from "vue";
import { useI18n } from "vue-i18n";
import { ApiError } from "@/types/errorTypes";
import { getPaged, exportData } from "../../services/invoicesApi";
import HomeFilters from "@/components/home/filters.vue";
import ExportViesDeclaration from "@/components/home/export-vies-declaration.vue";
import ExportActions from "@/components/home/export-actions.vue";
import grid from "@/components/grid/grid.vue";
import IListOptions from "@/interfaces/invoices/iListOptions";
import IListFilter from "@/interfaces/invoices/iListFilter";
import IFilterField from "@/interfaces/home/iFilterField";
import IProfile from "@/interfaces/users/iProfile";
import IDeclarantExtended from "@/interfaces/users/iDeclarantExtended";
import IExportType from "@/interfaces/invoices/iExportType";
import IUser from "@/interfaces/users/iUser";
import { getSession } from "@/services/authService";
import LoadingSpinner from "@/components/loading-spinner.vue";
import router from "@/router";
import ICompany from "@/interfaces/companies/iCompany";
import IConnection from "@/interfaces/users/iConnection";
import {
	getInitalLedgerOptions,
	getInitalListOptions,
	getInitFilterFields,
	getInitialRowKeysConfig,
	gridConfig,
} from "./config";
import { validateInvoicesRequiredFields } from "@/utils/customValidators/invoicesValidators";
import { errorToast } from "@/utils/toastr";

type LoadingFields =
	| "grid"
	| "export"
	| "companies"
	| "import"
	| "user"
	| "connection";


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
	user: {
		type: Object as () => IUser,
	},
	companies: {
		type: Array as () => ICompany[],
	},
	connection: {
		type: Object as () => IConnection,
	},
},
  emits: [
	"onImportModalOpen",
	"onFirstFilterTriggered",
	"onOpenConnections",
],
  setup(__props, { emit: __emit }) {

const localization = useI18n();

const invoicesValidateRequiredFields = [
	"customerVat",
	"customerCountry",
	"nraDocType",
	"number",
];

const props = __props;

const emit = __emit;

const companies = ref(
	[] as {
		label?: string;
		value: number;
	}[]
);

const data = ref({
	data: [],
	total: 0,
});

const filterFieldsConfig: Ref<IFilterField[]> = ref(
	getInitFilterFields(localization.t)
);

const loading = ref({
	grid: false,
	export: false,
	companies: false,
	import: false,
	user: false,
	connection: false,
});

const viesModalOpen = ref(false);
const firstLoadTriggered = ref(false);
const dataHasInvalidFields = ref(false);

const selections = ref({
	itemIds: [] as string[],
	excludedItemIds: [] as string[],
});

const isSelectAllMode = ref(false);
const initRowKeysConfig = ref(getInitialRowKeysConfig(localization.t));

const initalListOptions = getInitalListOptions();

watch(
	() => localization.locale.value,
	() => {
		initRowKeysConfig.value = getInitialRowKeysConfig(localization.t);

		filterFieldsConfig.value.forEach((filter) => {
			if (filter.name === "ledger") {
				filter.options = getInitalLedgerOptions(localization.t);
			}
		});
	}
);

const profileData = computed(() => {
	return toRaw({ ...(props.user || {}).profileData } as IProfile &
		IDeclarantExtended);
});

const exportButtonsDisabled = computed(() => {
	return (
		loading.value["export"] ||
		loading.value["grid"] ||
		dataHasInvalidFields.value
	);
});

const exportViesButtonDisabled = computed(() => {
	let isPurchasesOn = false;
	const foundFilter = listOptions.filters?.find((f) => f.field === "ledger");
	if (foundFilter && foundFilter.value === "purchases") {
		isPurchasesOn = true;
	}

	return isPurchasesOn;
});

const userDataUnwatch = watch(
	() => props.user,
	(newUserData, oldUserData) => {
		const sessionData = getSession();
		const hasSession = sessionData && !Number.isNaN(sessionData.session_id);

		if (!oldUserData?.userName && newUserData?.userName) {
			if (hasSession) {
				userDataUnwatch();
			}
		}
	}
);

const companiesDataUnwatch = watch(
	() => props.companies,
	(newData, oldData) => {
		if ((!oldData || oldData.length === 0) && newData && newData.length > 0) {
			const companiesFilter = filterFieldsConfig.value.find(
				(f) => f.name === "company"
			);

			if (companiesFilter) {
				companiesFilter.options = (newData || []).map((c) => {
					return {
						label: c.name,
						value: c.id,
					};
				});

				companies.value = companiesFilter.options;

				(listOptions.filters || []).push({
					field: "company",
					value: companiesFilter.options[0].value,
				});

				companiesFilter.initialValue =
					companiesFilter.options.length === 1
						? companiesFilter.options[0].value
						: null;
			}

			companiesDataUnwatch();
		}
	}
);

const listOptions: IListOptions = reactive(initalListOptions);

const handleChangeSelection = (newSelections: string[]) => {
	if (!isSelectAllMode.value) {
		selections.value.itemIds = newSelections;
	} else {
		selections.value.excludedItemIds = newSelections;
	}
};

const handleResetSelections = () => {
	selections.value.itemIds = [];
	selections.value.excludedItemIds = [];
};

const handleChangeSelectAllMode = (newMode: boolean) => {
	isSelectAllMode.value = newMode;
};

const handleChangePage = async (page: number) => {
	listOptions.pageIndex = page;

	try {
		const resultData = await executeAsync("grid", async () => {
			return await getPaged(listOptions);
		});

		const hasInvalidFields = validateInvoicesRequiredFields(
			resultData.data,
			invoicesValidateRequiredFields
		);
		if (!dataHasInvalidFields.value && hasInvalidFields) {
			dataHasInvalidFields.value = hasInvalidFields;
		}

		data.value = resultData;
	} catch (error) {
		const err = error as ApiError;
		errorToast(err, err.message);
	}
};

const handleChangeFilters = async (filters?: IListFilter[]) => {
	console.log("aa");
	(filters || []).forEach((filter) => {
		if (!listOptions.filters) {
			listOptions.filters = [];
		}

		const foundFilter = listOptions.filters.find(
			(f) => f.field === filter.field
		);
		if (foundFilter) {
			foundFilter.value = filter.value;
		} else {
			const newFilter: IListFilter = {
				field: filter.field,
				value: filter.value,
			};

			listOptions.filters?.push(newFilter);
		}
	});
	listOptions.pageIndex = 0;
	listOptions.sort = undefined;
};

const handleChangeSorting = async (
	field: string,
	direction: "asc" | "desc"
) => {
	listOptions.sort = {
		field,
		direction,
	};
	listOptions.pageIndex = 0;

	try {
		data.value = await executeAsync("grid", async () => {
			return await getPaged(listOptions);
		});
	} catch (error) {
		const err = error as ApiError;
		errorToast(err, err.message);
	}
};

const handleExportData = async (
	exportType: IExportType,
	declarantData?: IProfile & IDeclarantExtended
) => {
	const exportItemIds = isSelectAllMode.value
		? selections.value.excludedItemIds
		: selections.value.itemIds;

	try {
		await executeAsync("export", async () => {
			return exportData(
				exportItemIds,
				isSelectAllMode.value,
				exportType,
				listOptions,
				declarantData
			);
		});
	} catch (error) {
		const err = error as ApiError;
		errorToast(err, err.message);
	}
};

const handleApplyFilters = async () => {
	listOptions.pageIndex = 0;
	listOptions.sort = undefined;

	firstLoadTriggered.value = true;
	emit("onFirstFilterTriggered");

	if (dataHasInvalidFields.value !== false) {
		dataHasInvalidFields.value = false;
	}

	try {
		const resultData = await executeAsync("grid", async () => {
			return await getPaged(listOptions);
		});

		const hasInvalidFields = validateInvoicesRequiredFields(
			resultData.data,
			invoicesValidateRequiredFields
		);
		if (hasInvalidFields) {
			dataHasInvalidFields.value = hasInvalidFields;
		}

		data.value = resultData;
	} catch (error) {
		const err = error as ApiError;
		errorToast(err, err.message);
	}
};

const handleViesModalOpen = () => {
	viesModalOpen.value = true;
};

const handleViesModalClose = (
	declarantData?: IProfile & IDeclarantExtended
) => {
	if (declarantData) {
		const exportType: IExportType = {
			fileType: "TXT",
			includeVies: true,
		};
		handleExportData(exportType, declarantData);
	}
	viesModalOpen.value = !viesModalOpen.value;
};

const handleImportModalOpen = () => {
	emit("onImportModalOpen");
};

// TODO extract in HoC:
const executeAsync = async (
	loadingField: LoadingFields,
	exec: any,
	options?: { [key: string]: string; value: string }
) => {
	loading.value[loadingField] = true;
	const showToast = options?.showToast || true;
	const rethrowError = options?.rethrowError || true;

	try {
		const result = await exec();
		loading.value[loadingField] = false;
		return result;
	} catch (error) {
		const err = error as ApiError;
		if (err.statusCode === 401) {
			router.push("/login");
		} else {
			if (showToast) {
				const err = error as ApiError;
				if (process.env.NODE_ENV === "production") {
					if (err.apiCode === 150) {
						console.log(err);
						errorToast(err, err.innerMessage);
					}
				} else {
					console.log(err);
					errorToast(err, err.innerMessage);
				}
			}

			if (rethrowError) {
				throw error;
			}
		}
	} finally {
		loading.value[loadingField] = false;
	}
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["home-wrapper", { initial: !firstLoadTriggered.value }])
  }, [
    (loading.value.companies)
      ? (_openBlock(), _createBlock(LoadingSpinner, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      (!firstLoadTriggered.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              (__props.connection)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createTextVNode(_toDisplayString(_unref(localization).t("home.filter-message")) + " ", 1),
                    _createElementVNode("div", {
                      class: "btn",
                      onClick: handleImportModalOpen
                    }, _toDisplayString(_unref(localization).t("home.import-convert")), 1),
                    _createTextVNode(" " + _toDisplayString(_unref(localization).t("home.vat-file")), 1)
                  ]))
                : _createCommentVNode("", true),
              (!__props.connection)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createTextVNode(_toDisplayString(_unref(localization).t("app.no-connection")) + ". ", 1),
                    _createElementVNode("div", {
                      class: "btn",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('onOpenConnections')))
                    }, _toDisplayString(_unref(localization).t("ho-modal-connect.select-connection")), 1)
                  ]))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true),
      (__props.connection && __props.connection.active)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(HomeFilters, {
                loading: loading.value,
                filterFields: filterFieldsConfig.value,
                filters: listOptions.filters,
                onOnFiltersChange: handleChangeFilters,
                onOnApplyFilters: handleApplyFilters
              }, null, 8, ["loading", "filterFields", "filters"])
            ]),
            _createElementVNode("div", _hoisted_8, [
              (firstLoadTriggered.value)
                ? (_openBlock(), _createBlock(grid, {
                    key: 0,
                    loading: loading.value,
                    config: _unref(gridConfig),
                    data: data.value.data,
                    total: data.value.total,
                    listOptions: listOptions,
                    hasDataErrors: dataHasInvalidFields.value,
                    isSelectAllMode: isSelectAllMode.value,
                    selections: selections.value,
                    rowKeysConfig: initRowKeysConfig.value,
                    onOnChangePage: handleChangePage,
                    onOnChangeFilters: handleChangeFilters,
                    onOnChangeSorting: handleChangeSorting,
                    onOnChangeSelection: handleChangeSelection,
                    onOnChangeSelectAllMode: handleChangeSelectAllMode,
                    onOnResetSelection: handleResetSelections
                  }, {
                    topBarPlaceholder: _withCtx(() => [
                      _createVNode(ExportActions, {
                        loading: loading.value.export,
                        hasDataErrors: dataHasInvalidFields.value,
                        exportButtonsDisabled: exportButtonsDisabled.value,
                        exportViesButtonDisabled: exportViesButtonDisabled.value,
                        onOnExportClick: handleExportData,
                        onOnExportViesClick: handleViesModalOpen
                      }, null, 8, ["loading", "hasDataErrors", "exportButtonsDisabled", "exportViesButtonDisabled"])
                    ]),
                    emptyGridResults: _withCtx(() => [
                      _createElementVNode("p", null, _toDisplayString(_unref(localization).t("grid.export-empty-results")), 1),
                      _createVNode(ExportActions, {
                        loading: loading.value.export,
                        hasDataErrors: dataHasInvalidFields.value,
                        exportButtonsDisabled: exportButtonsDisabled.value,
                        exportViesVisible: false,
                        onOnExportClick: handleExportData
                      }, null, 8, ["loading", "hasDataErrors", "exportButtonsDisabled"])
                    ]),
                    _: 1
                  }, 8, ["loading", "config", "data", "total", "listOptions", "hasDataErrors", "isSelectAllMode", "selections", "rowKeysConfig"]))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true),
      (viesModalOpen.value)
        ? (_openBlock(), _createBlock(ExportViesDeclaration, {
            key: 2,
            profileData: profileData.value,
            onOnExportModalClose: handleViesModalClose
          }, null, 8, ["profileData"]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}
}

})