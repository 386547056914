import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "w-8 h-8 text-center" }

import { useI18n } from 'vue-i18n';
import LanguageFlags from '@/components/svg/language-flags.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'locale-switch',
  setup(__props) {

const localization = useI18n();
const locales = localization.availableLocales;

const handleSelect = (locale: string) => {
	localization.locale.value = locale;
	localStorage.setItem('lang', locale);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("ul", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(locales), (locale, i) => {
      return (_openBlock(), _createElementBlock("li", {
        onClick: ($event: any) => (handleSelect(locale)),
        key: `locale-${i}`
      }, [
        _createElementVNode("span", _hoisted_2, [
          _createVNode(LanguageFlags, {
            class: "h-6 w-6 rounded-full",
            selectedLanguage: locale
          }, null, 8, ["selectedLanguage"])
        ]),
        _createElementVNode("label", null, _toDisplayString(_unref(localization).t(`languages.${locale}FullName`)), 1)
      ], 8, _hoisted_1))
    }), 128))
  ]))
}
}

})