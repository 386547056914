import IInvoice from "@/interfaces/invoices/iInvoice";

export const validateInvoicesRequiredFields = (
	invoices: IInvoice[],
	requiredFields: string[]
): boolean => {
	let hasInvalidRequiredValue = false;
	for (let i = 0; i < invoices.length; i++) {
		const invoice = invoices[i];
		const keys = Object.keys(invoice);
		keys.forEach((k) => {
			if (
				requiredFields.includes(k) &&
				!(invoice as { [key: string]: any })[k]
			) {
				hasInvalidRequiredValue = true;
			}
		});

		if (hasInvalidRequiredValue) {
			break;
		}
	}

	return hasInvalidRequiredValue;
};
