import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ho-month-year-selector flex items-center" }
const _hoisted_2 = { class: "ho-selector-month" }
const _hoisted_3 = { class: "ho-selector-year" }

import { ref, watch, computed } from "vue";
import { useI18n } from "vue-i18n";
import Autocomplete from "./autocomplete.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'month-year-selector',
  props: {
	defaultMonth: {
		type: Number,
		default: null,
	},
	defaultYear: {
		type: Number,
		default: null,
	},
},
  emits: ["onChange"],
  setup(__props, { emit: __emit }) {

const localization = useI18n();
const currDate = new Date();

const props = __props;

const getTranslatedMonths = (): string[] => {
	return [
		localization.t("month-year-selector.months.january"),
		localization.t("month-year-selector.months.february"),
		localization.t("month-year-selector.months.march"),
		localization.t("month-year-selector.months.april"),
		localization.t("month-year-selector.months.may"),
		localization.t("month-year-selector.months.june"),
		localization.t("month-year-selector.months.july"),
		localization.t("month-year-selector.months.august"),
		localization.t("month-year-selector.months.september"),
		localization.t("month-year-selector.months.october"),
		localization.t("month-year-selector.months.november"),
		localization.t("month-year-selector.months.december"),
	];
};
const emit = __emit;

const monthRef = ref(props.defaultMonth || currDate.getMonth());
const yearRef = ref(props.defaultYear || currDate.getFullYear());
const monthsNames = ref(getTranslatedMonths());

const yearsOptions: number[] = [];
for (let i = 2021; i <= currDate.getFullYear(); i++) {
	yearsOptions.push(i);
}

watch(
	() => localization.locale.value,
	() => {
		monthsNames.value = getTranslatedMonths();
	}
);

const monthsOptions = computed(() => {
	return monthsNames.value.map((m, i) => ({
		label: m,
		value: i,
	}));
});

const handleMonthSelect = (month: number) => {
	monthRef.value = month;
	valuesChanged(month, yearRef.value);
};

const handleYearsSelect = (year: number) => {
	yearRef.value = year;
	valuesChanged(monthRef.value, year);
};

const valuesChanged = (month: number, year: number) => {
	emit("onChange", month, year);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(Autocomplete, {
        class: "ui-test-autocomplete-month",
        placeholder: 
					_unref(localization).t('month-year-selector.placeholders.select-month')
				,
        options: monthsOptions.value,
        defaultValue: monthRef.value,
        canClear: false,
        onOnChange: handleMonthSelect
      }, null, 8, ["placeholder", "options", "defaultValue"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(Autocomplete, {
        class: "ml-3 ui-test-autocomplete-year",
        placeholder: 
					_unref(localization).t('month-year-selector.placeholders.select-year')
				,
        options: yearsOptions,
        defaultValue: yearRef.value,
        canClear: false,
        onOnChange: handleYearsSelect
      }, null, 8, ["placeholder", "defaultValue"])
    ])
  ]))
}
}

})