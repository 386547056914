import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "form-row" }
const _hoisted_2 = { class: "connection-label" }
const _hoisted_3 = { class: "form-cell flex-1" }
const _hoisted_4 = { class: "flex" }
const _hoisted_5 = { class: "form-cell flex-1" }
const _hoisted_6 = { class: "form-cell flex-1" }
const _hoisted_7 = { class: "form-cell flex-1" }
const _hoisted_8 = { class: "form-cell flex-none" }
const _hoisted_9 = {
  key: 0,
  class: "flex"
}
const _hoisted_10 = { class: "form-cell flex-1" }

import { computed, onBeforeMount, ref, toRaw, watch, Ref } from 'vue'
import { useI18n } from 'vue-i18n';
import HoForm from "../form/ho-form.vue";
import HoText from "../form/ho-text.vue";
import IConnection from '@/interfaces/users/iConnection';
import { EyeIcon, EyeOffIcon } from '@heroicons/vue/solid';
import { IValidator } from '@/utils/validator';

export type ValidationField = 'db' | 'url' | 'username' | 'password' | 'version' | 'alias';

export default /*@__PURE__*/_defineComponent({
  __name: 'edit-connection-form',
  props: {
	connection: {
		type: Object as () => IConnection,
		default: () => ({ active: false } as IConnection)
	},
	loading: Boolean,
	editMode: {
		type: Boolean,
		default: false
	},
	resetValidation: {
		type: Boolean,
		default: false
	},
	allConnections: {
		type: Array as () => IConnection[],
		default: () => []
	},
	hidePassword: {
		type: Boolean,
		default: false
	}
},
  emits: ['onEditConnectionClose', 'onCancel', 'onResetValidation'],
  setup(__props, { emit: __emit }) {

const localization = useI18n();

const uniquenessValidatorCollections = {
	db: [],
	url: [],
	username: [],
	password: [],
	version: [],
	alias: []
} as { [key: string]: any[] }

const getValidators = () => {
	const validators: {
		[key:string]: any[]
	} = {
		db: [
			{
				type: 'notEmpty',
				message: localization.t('edit-connection-form.validators.database-required')
			}, {
				type: 'unique',
				options: {
					collection: uniquenessValidatorCollections.db,
					caseSensitive: true
				},
				message: localization.t('edit-connection-form.validators.database-uniqueness')
			}
		],
		url: [
			{
				type: 'notEmpty',
				message: localization.t('edit-connection-form.validators.url-required')
			},
			{
				type: 'pattern',
				options: {
					pattern: /^(http[s]?:\/\/)?(www\.)?.+\.[a-zA-Z0-9]+$/,
				},
				message: localization.t('edit-connection-form.validators.url-format')
			}
		],
		username: [{
			type: 'notEmpty',
			message: localization.t('edit-connection-form.validators.username-required')
		}],
		password: [{
			type: 'notEmpty',
			message: localization.t('edit-connection-form.validators.password-required')
		}],
		version: [
			{
				type: 'notEmpty',
				message: localization.t('edit-connection-form.validators.version-required')
			},
			{
				type: 'pattern',
				options: {
					pattern: /^\d+$/,
				},
				message: localization.t('edit-connection-form.validators.version-digits')
			}
		],
		active:[],
		alias:[{
			type: 'unique',
			options: {
				collection: uniquenessValidatorCollections.alias,
				caseSensitive: true
			},
			message: localization.t('edit-connection-form.validators.alias-uniqueness')
		}]
	}

	return validators;
}

const props = __props;
const emit = __emit;
const isPasswordMasked = ref(true);
const currentConnection: Ref<IConnection> = ref({ ...toRaw(props.connection) });
const resetValidation = ref(props.resetValidation);

const validState: Ref<{ [key: string]: boolean }> = ref({
	url: currentConnection.value.url ? true : false,
	db: currentConnection.value.db ? true : false,
	version: currentConnection.value.version ? true : false,
	username: currentConnection.value.username ? true : false,
	password: currentConnection.value.password ? true : false,
	alias: true
});

const disableOkButton = computed(() => {
	return props.loading
		|| !validState.value.url
		|| !validState.value.db
		|| !validState.value.version
		|| !validState.value.username
		|| !validState.value.password
		|| !validState.value.alias
});

watch(() => props.resetValidation, () => {
	handleResetValidation();
	resetValidation.value = props.resetValidation;
});

watch(() => props.connection, () => {
	currentConnection.value = { ...props.connection };
	validState.value = {
		url: currentConnection.value.url ? true : false,
		db: currentConnection.value.db ? true : false,
		version: currentConnection.value.version ? true : false,
		username: currentConnection.value.username ? true : false,
		password: currentConnection.value.password ? true : false,
		alias: true
	};
	addCollectionToUniquenessValidators()
});

const addCollectionToUniquenessValidators = () => {
	(Object.keys(uniquenessValidatorCollections) as ValidationField[]).forEach((field: ValidationField) => {
		const currentValidator = getValidators()[field].find(v => v.type === 'unique') as IValidator;
		if (currentValidator) {
			const collection = [...props.allConnections]
			.map(conn => conn[field])
			.filter(key => key !== currentConnection.value[field] && key !== null);
			
			uniquenessValidatorCollections[field] = collection
		}
	});
}

onBeforeMount(() => addCollectionToUniquenessValidators());

const togglePasswordMask = () => {
	isPasswordMasked.value = !isPasswordMasked.value
}

const handleResetValidation = () => {
	validState.value = {
		url: currentConnection.value.url ? true : false,
		db: currentConnection.value.db ? true : false,
		version: currentConnection.value.version ? true : false,
		username: currentConnection.value.username ? true : false,
		password: currentConnection.value.password ? true : false,
		alias: true
	};
	emit('onResetValidation')
}
const handleValidationStateChange = (key: ValidationField, value: boolean) => {
	validState.value[key] = value;
};
const handleModalOk = () => {
	currentConnection.value.version = Number(currentConnection.value.version);

	emit('onEditConnectionClose', currentConnection.value);
}
const handleModalCancel = () => {
	emit('onEditConnectionClose');
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(HoForm, {
    modelValue: currentConnection.value,
    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((currentConnection).value = $event)),
    class: "form-gray mt-4",
    onOnOk: handleModalOk,
    onOnCancel: handleModalCancel,
    okButtonText: __props.loading ? (__props.editMode ? _unref(localization).t('common.saving') : _unref(localization).t('common.adding')) : (__props.editMode ? _unref(localization).t('common.save') : _unref(localization).t('common.add')),
    cancelButtonText: _unref(localization).t('common.cancel'),
    disableOk: disableOkButton.value
  }, {
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h3", _hoisted_2, [
          _createTextVNode(_toDisplayString(__props.editMode ? `${__props.connection.db}` : _unref(localization).t('edit-connection-form.add-new-connection')) + " ", 1),
          (__props.editMode)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: _normalizeClass(__props.connection.active ? 'active' : '')
              }, _toDisplayString(__props.connection.active ? ` (${_unref(localization).t('edit-connection-form.active')})` : ` (${_unref(localization).t('edit-connection-form.not-active')})`), 3))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(HoText, {
              modelKey: "url",
              label: _unref(localization).t('edit-connection-form.labels.url'),
              modelValue: currentConnection.value.url,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((currentConnection.value.url) = $event)),
              validation: getValidators().url,
              resetValidation: resetValidation.value,
              onOnResetValidation: handleResetValidation,
              onOnValidStateChange: handleValidationStateChange
            }, null, 8, ["label", "modelValue", "validation", "resetValidation"])
          ]),
          _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1)),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(HoText, {
                modelKey: "alias",
                label: _unref(localization).t('edit-connection-form.labels.alias'),
                modelValue: currentConnection.value.alias,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((currentConnection.value.alias) = $event)),
                validation: getValidators().alias,
                resetValidation: resetValidation.value,
                onOnValidStateChange: handleValidationStateChange,
                onOnResetValidation: handleResetValidation
              }, null, 8, ["label", "modelValue", "validation", "resetValidation"])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(HoText, {
                modelKey: "db",
                label: _unref(localization).t('edit-connection-form.labels.database'),
                modelValue: currentConnection.value.db,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((currentConnection.value.db) = $event)),
                validation: getValidators().db,
                resetValidation: resetValidation.value,
                onOnValidStateChange: handleValidationStateChange,
                onOnResetValidation: handleResetValidation
              }, null, 8, ["label", "modelValue", "validation", "resetValidation"])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(HoText, {
                modelKey: "version",
                label: _unref(localization).t('edit-connection-form.labels.version'),
                modelValue: currentConnection.value.version,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((currentConnection.value.version) = $event)),
                validation: getValidators().version,
                resetValidation: resetValidation.value,
                onOnValidStateChange: handleValidationStateChange,
                onOnResetValidation: handleResetValidation
              }, null, 8, ["label", "modelValue", "validation", "resetValidation"])
            ])
          ]),
          _cache[8] || (_cache[8] = _createElementVNode("br", null, null, -1)),
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(HoText, {
                modelKey: "username",
                label: _unref(localization).t('common.user.username'),
                modelValue: currentConnection.value.username,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((currentConnection.value.username) = $event)),
                validation: getValidators().username,
                resetValidation: resetValidation.value,
                onOnValidStateChange: handleValidationStateChange,
                onOnResetValidation: handleResetValidation
              }, null, 8, ["label", "modelValue", "validation", "resetValidation"])
            ])
          ]),
          _cache[9] || (_cache[9] = _createElementVNode("br", null, null, -1)),
          (!__props.hidePassword)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(HoText, {
                    modelKey: "password",
                    label: _unref(localization).t('edit-connection-form.labels.password'),
                    modelValue: currentConnection.value.password,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((currentConnection.value.password) = $event)),
                    isMasked: isPasswordMasked.value,
                    validation: getValidators().password,
                    resetValidation: resetValidation.value,
                    onOnValidStateChange: handleValidationStateChange,
                    onOnResetValidation: handleResetValidation
                  }, null, 8, ["label", "modelValue", "isMasked", "validation", "resetValidation"])
                ]),
                _createElementVNode("span", {
                  class: "ml-2 mb-5 text-center self-end",
                  onClick: togglePasswordMask
                }, [
                  (isPasswordMasked.value)
                    ? (_openBlock(), _createBlock(_unref(EyeIcon), {
                        key: 0,
                        class: "w-7 h-7"
                      }))
                    : _createCommentVNode("", true),
                  (!isPasswordMasked.value)
                    ? (_openBlock(), _createBlock(_unref(EyeOffIcon), {
                        key: 1,
                        class: "w-7 h-7"
                      }))
                    : _createCommentVNode("", true)
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "okButtonText", "cancelButtonText", "disableOk"]))
}
}

})