import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["value"]
const _hoisted_2 = {
  key: 0,
  class: "text-red-500"
}

import { IValidator, validate } from '@/utils/validator';
import { computed, Ref, ref, WritableComputedRef } from 'vue';

// TODO: importing type props interface to use in defineProps should work with vue@3.3.4
// but it seems still not working. Test around
interface IHoSelectProps {
	label?: string,
	placeholder?: string,
	modelValue?: string | number,
	modelKey: string
	validation?: IValidator[],
	selectOptionsList: {
		label: string,
		value: string,
		diabled?: boolean
	}[]
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ho-select',
  props: {
    label: {},
    placeholder: {},
    modelValue: {},
    modelKey: {},
    validation: {},
    selectOptionsList: {}
  },
  emits: ['update:modelValue', 'onValidStateChange', 'onResetValidation'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const errorMessage: Ref<string | null> = ref(null);

const value: WritableComputedRef<string | number> = computed({
	get: () => {
		return props.modelValue || "";
	},
	set: (value) => {
		const errors = validate(value, props.validation as IValidator[]);
		const identifier = props.modelKey ? props.modelKey : props.label;

		if (errors && errors.length > 0) {
			errorMessage.value = errors.join(", ");
			emit('onValidStateChange', identifier, false);
		} else {
			errorMessage.value = null;
			emit('onValidStateChange', identifier, true);
		}
		emit('update:modelValue', value);
	}
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("label", null, _toDisplayString(_ctx.label), 1),
    _withDirectives(_createElementVNode("select", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((value).value = $event))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectOptionsList, (option, index) => {
        return (_openBlock(), _createElementBlock("option", {
          key: index,
          value: option.value
        }, _toDisplayString(option.label), 9, _hoisted_1))
      }), 128))
    ], 512), [
      [_vModelSelect, value.value]
    ]),
    (errorMessage.value)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(errorMessage.value), 1))
      : _createCommentVNode("", true)
  ], 64))
}
}

})