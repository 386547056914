import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withCtx as _withCtx, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "import-form"
}
const _hoisted_2 = { class: "form-row" }
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = { class: "form-cell flex-none w-[150px]" }
const _hoisted_5 = { class: "form-cell flex-1" }
const _hoisted_6 = ["placeholder"]
const _hoisted_7 = {
  key: 0,
  class: "text-red-500"
}
const _hoisted_8 = { class: "form-row" }
const _hoisted_9 = { class: "flex" }
const _hoisted_10 = { class: "w-96" }
const _hoisted_11 = { class: "autocomplete-holder inline-block" }
const _hoisted_12 = { class: "flex-1 form-cell" }
const _hoisted_13 = { class: "autocomplete-holder" }
const _hoisted_14 = { class: "form-row" }
const _hoisted_15 = ["accept"]
const _hoisted_16 = {
  key: 0,
  class: "text-red-500"
}
const _hoisted_17 = {
  key: 1,
  class: "import-loading"
}
const _hoisted_18 = { class: "text-3xl loading-text" }
const _hoisted_19 = {
  key: 2,
  class: "import-success"
}
const _hoisted_20 = { class: "text-center 3xl" }
const _hoisted_21 = { class: "text-center" }
const _hoisted_22 = ["href", "download"]

import { computed, reactive, ref, watch, Ref } from 'vue'
import { useI18n } from 'vue-i18n';
import HoModalDialog from "../modal/ho-modal-dialog.vue";
import Autocomplete from '../form/autocomplete.vue';
import MonthYearSelector from "../form/month-year-selector.vue";
import { CheckCircleIcon } from '@heroicons/vue/outline'
import IConvertDeclarationResult from "@/interfaces/home/iConvertDeclarationResult";
import IDeclarationData from "@/interfaces/home/iDeclarationData";
import { validate } from '@/utils/validator';
import IProfile from '@/interfaces/users/iProfile';
import IDeclarantExtended from '@/interfaces/users/iDeclarantExtended';
import HoForm from "../form/ho-form.vue";
import HoText from "../form/ho-text.vue";
import ICompany from '@/interfaces/companies/iCompany';
import { subMonths } from 'date-fns';

type StateType = 'select' | 'loading' | 'ready';


export default /*@__PURE__*/_defineComponent({
  __name: 'import-external-invoices',
  props: {
	loading: Boolean,
	importResult: {
		type: Object as () => IConvertDeclarationResult
	},
	companies: {
		type: Array as () => ICompany[]
	},
	declarantData: {
		type: Object as () => IProfile & IDeclarantExtended
	}
},
  emits: ['onUploadModalClose'],
  setup(__props, { emit: __emit }) {

const localization = useI18n();

const VALID_FILE_TYPES = [
	"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", 
	"application/vnd.ms-excel"
];

const validateFile = (file: File) => {
	const errors = [];

	if (file.size > 5 * 1000000) {
		errors.push(localization.t('import-external-invoices.validators.file-size', { max: 5 }));
	}

	if (VALID_FILE_TYPES.indexOf(file.type) === -1) {
		errors.push(localization.t('import-external-invoices.validators.file-type'));
	}

	return errors;
}

const props = __props;
const emit = __emit;

const state: Ref<StateType> = ref('select');
const validFileType = ref(VALID_FILE_TYPES.join(","));
const errors: Ref<{[name: string]: string}> = ref({});
const formValid = ref(false);
const validFileSelected = ref(false);

let prefilledFullName = "";
if (props.declarantData) {
	if (props.declarantData.firstName) {
		prefilledFullName = `${props.declarantData.firstName}`;
	}

	if (props.declarantData.middleName) {
		prefilledFullName = `${prefilledFullName} ${props.declarantData.middleName}`;
	}

	if (props.declarantData.lastName) {
		prefilledFullName = `${prefilledFullName} ${props.declarantData.lastName}`;
	}
}

const importFormValues = reactive({
	companyId: props.companies && (props.companies || []).length === 1 ? props.companies[0].id : undefined,
	monthIndex: subMonths(new Date(), 1).getMonth(),
	year:  subMonths(new Date(), 1).getFullYear(),
	fullName: prefilledFullName,
	unifiedCivilNumber: props.declarantData ? props.declarantData.ucn : ""
});

let selectedFile: File | null = null;

const companyOptions = computed(() => {
	return props.companies?.map(c => ({
		label: c.name,
		value: c.id
	}));
});

watch([importFormValues, validFileSelected], () => {
	const hasEmptyProps = (importFormValues.companyId === undefined || importFormValues.companyId === null)
		|| (importFormValues.fullName === null || importFormValues.fullName.trim().length === 0)
		|| (importFormValues.unifiedCivilNumber === null || importFormValues.unifiedCivilNumber.trim().length === 0)
		|| (importFormValues.monthIndex === undefined || importFormValues.monthIndex === null)
		|| (importFormValues.year === undefined || importFormValues.year === null)
		|| selectedFile === null;

	// TODO: Extract validation checking in a form element validation component
	const ucnErrors = validate(importFormValues.unifiedCivilNumber, [{
		type: 'len',
		options: {
			min: 10,
			max: 10,
		},
		message: localization.t('common.profile.validators.ucn-digits', { exact: 10 })
	}]);
	const fullNameErrors = validate(importFormValues.fullName, [{
		type: 'len',
		options: {
			min: 2,
			max: 39,
		},
		message: localization.t('common.profile.validators.full-name-length', { min: 2, max: 39 })
	}]);
	
	if (fullNameErrors.length > 0) {
		errors.value = Object.assign(
			{},
			errors.value,
			{
				['name']: fullNameErrors.join(", ")
			}
		);
	} else {
		delete errors.value['name'];
	}
	if (ucnErrors.length > 0) {
		errors.value = Object.assign(
			{},
			errors.value,
			{
				['ucn']: ucnErrors.join(", ")
			}
		);
	} else {
		delete errors.value['ucn'];
	}
	formValid.value = !hasEmptyProps 
		&& Object.keys(errors.value).length === 0
		&& validFileSelected.value === true;
});

const disableImportButton = computed(() => {
	if (props.loading === true) {
		return true;
	}

	const filledForm = formValid.value === false;
	return filledForm;
});

watch(() => [props.loading, props.importResult], () => {
	const newState: StateType = props.loading 
		? 'loading'
		: props.importResult ? 'ready' : 'select';

	state.value = newState;
});

const handleUploadFile = (ev: any) => {
	const file = ev.target.files[0] as File;
	const fileErrors = validateFile(file);

	if (fileErrors.length > 0) {
		errors.value['file'] = fileErrors.join(", ");
		selectedFile = null;
		validFileSelected.value = false;
	} else {
		delete errors.value['file'];
		selectedFile = file;
		validFileSelected.value = true;
	}
}

const handleCompanySelect = (value: number) => {
	importFormValues.companyId = value;
	handleModalFocusChange();
}

const handleDateChange = (month:number, year:number) => {
	importFormValues.monthIndex = month;
	importFormValues.year = year;
	handleModalFocusChange();
};

const handleModalFocusChange = () => {
	document.getElementById('ho-modal-dialog')?.focus();
}

const handleModalOk = () => {
	const { monthIndex, ...rest } = importFormValues;
	const submitValues: IDeclarationData = {
		...rest,
		month: monthIndex + 1
	};
	emit('onUploadModalClose', selectedFile, {...submitValues});
}

const handleModalCancel = () => {
	emit('onUploadModalClose');
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(HoModalDialog, {
    headerText: _unref(localization).t('import-external-invoices.convert-from-vat'),
    okButtonText: _unref(localization).t('import-external-invoices.upload'),
    cancelButtonText: state.value === 'ready' ? _unref(localization).t('common.close') : _unref(localization).t('common.cancel'),
    width: 720,
    onOnOk: handleModalOk,
    onOnCancel: handleModalCancel,
    hideCancel: state.value === 'loading',
    hideOk: state.value !== 'select',
    disableOk: disableImportButton.value
  }, {
    body: _withCtx(() => [
      (state.value==='select')
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("p", null, _toDisplayString(_unref(localization).t('import-external-invoices.upload-odoo-declaration')), 1),
            _createVNode(HoForm, {
              modelValue: importFormValues,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((importFormValues) = $event)),
              class: "form-gray mt-4",
              hideOk: true,
              hideCancel: true
            }, {
              body: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("h3", null, _toDisplayString(_unref(localization).t('import-external-invoices.labels.submitted-by')), 1),
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode(HoText, {
                        label: _unref(localization).t('common.profile.labels.ucn'),
                        modelValue: importFormValues.unifiedCivilNumber,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((importFormValues.unifiedCivilNumber) = $event)),
                        validation: [{
										type: 'len',
										options: {
											min: 10,
											max: 10
										},
										message: _unref(localization).t('common.profile.validators.ucn-digits', { exact: 10 })
									}]
                      }, null, 8, ["label", "modelValue", "validation"])
                    ]),
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("label", null, _toDisplayString(_unref(localization).t('common.profile.labels.full-name')), 1),
                      _withDirectives(_createElementVNode("input", {
                        type: "text",
                        placeholder: _unref(localization).t('import-external-invoices.placeholders.declarant-name'),
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((importFormValues.fullName) = $event))
                      }, null, 8, _hoisted_6), [
                        [_vModelText, importFormValues.fullName]
                      ]),
                      (errors.value.name)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(errors.value.name), 1))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("h3", null, _toDisplayString(_unref(localization).t('import-external-invoices.labels.declaration-period')), 1),
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("label", null, _toDisplayString(_unref(localization).t('month-year-selector.period')), 1),
                      _createElementVNode("div", _hoisted_11, [
                        _createVNode(MonthYearSelector, {
                          "default-month": importFormValues.monthIndex,
                          "default-year": importFormValues.year,
                          onOnChange: handleDateChange
                        }, null, 8, ["default-month", "default-year"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("label", null, _toDisplayString(_unref(localization).t('import-external-invoices.labels.company')), 1),
                      _createElementVNode("div", _hoisted_13, [
                        _createVNode(Autocomplete, {
                          placeholder: _unref(localization).t('import-external-invoices.placeholders.select-company'),
                          options: companyOptions.value,
                          defaultValue: importFormValues.companyId,
                          canClear: false,
                          onOnChange: handleCompanySelect
                        }, null, 8, ["placeholder", "options", "defaultValue"])
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("h3", null, _toDisplayString(_unref(localization).t('import-external-invoices.labels.select-odoo-file')), 1),
                  _createElementVNode("input", {
                    type: "file",
                    name: "file",
                    accept: validFileType.value,
                    onChange: handleUploadFile,
                    onKeypress: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"])),
                    required: ""
                  }, null, 40, _hoisted_15)
                ]),
                (errors.value.file)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_16, _toDisplayString(errors.value.file), 1))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]))
        : _createCommentVNode("", true),
      (state.value==='loading')
        ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
            _createElementVNode("p", _hoisted_18, _toDisplayString(_unref(localization).t('import-external-invoices.converting')), 1)
          ]))
        : _createCommentVNode("", true),
      (state.value==='ready')
        ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
            _createElementVNode("p", _hoisted_20, [
              _createVNode(_unref(CheckCircleIcon), { class: "w-24 m-auto" }),
              _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1)),
              _createTextVNode(_toDisplayString(_unref(localization).t('import-external-invoices.successful-conversion')), 1)
            ]),
            _createElementVNode("p", _hoisted_21, [
              _createElementVNode("a", {
                class: "btn",
                href: (__props.importResult || {}).downloadUrl,
                download: (__props.importResult || {}).fileName
              }, _toDisplayString(_unref(localization).t('import-external-invoices.download-txt')), 9, _hoisted_22)
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["headerText", "okButtonText", "cancelButtonText", "hideCancel", "hideOk", "disableOk"]))
}
}

})