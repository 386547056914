import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { useI18n } from 'vue-i18n';
import HoModalDialog from "../modal/ho-modal-dialog.vue";
import DeclarantDataForm from "./declarantDataForm/index.vue";
import IProfile from "@/interfaces/users/iProfile";
import IDeclarantExtended from '@/interfaces/users/iDeclarantExtended';


export default /*@__PURE__*/_defineComponent({
  __name: 'declarant-data-dialog',
  props: {
	profileData: {
		type: Object as () => IProfile & IDeclarantExtended,
	}
},
  emits: ['onUpdateDeclarantClose'],
  setup(__props, { emit: __emit }) {

const localization = useI18n();


const emit = __emit;

const handleDialogClose = (data: IProfile & IDeclarantExtended) => {
	emit("onUpdateDeclarantClose", data);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(HoModalDialog, {
    headerText: _unref(localization).t('declarant-data-dialog.declarant-data-title'),
    width: 640,
    disableOk: "",
    disableCancel: "",
    hideFooter: ""
  }, {
    body: _withCtx(() => [
      _createVNode(DeclarantDataForm, {
        declarantData: __props.profileData,
        onOnClose: handleDialogClose
      }, null, 8, ["declarantData"])
    ]),
    _: 1
  }, 8, ["headerText"]))
}
}

})