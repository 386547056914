import { ApiError } from "@/types/errorTypes";
import { createToast, ToastOptions } from "mosha-vue-toastify";

const TOAST_CONFIG = {
	position: "top-right",
};

export const TOAST_TYPES = {
	ERROR: {
		...TOAST_CONFIG,
		type: "danger",
		timeout: 10000,
	},
	CLOSABLE_ERROR: {
		...TOAST_CONFIG,
		type: "danger",
		timeout: -1,
	},
};

export const errorToast = (
	error: ApiError,
	errorMessage?: string
): { close: () => void } => {
	const errMessage = errorMessage ?? error.message;

	if (error.apiCode === 150) {
		return createToast(errMessage, TOAST_TYPES.CLOSABLE_ERROR as ToastOptions);
	}

	return createToast(errMessage, TOAST_TYPES.ERROR as ToastOptions);
};
