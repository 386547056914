<template>
	<router-view
		v-if="!loading.user"
		:user="userData"
		@onReloadUser="handleReloadMyUser"
	/>
</template>

<script setup lang="ts">
import "mosha-vue-toastify/dist/style.css";
import { onMounted, ref } from "vue";
import router from "./router";
import { isAuthenticated } from "@/services/authService";
import { getMyUser } from "@/services/userApi";
import IUser from "./interfaces/users/iUser";
import { ApiError } from "./types/errorTypes";
import { errorToast } from "@/utils/toastr";

type LoadingFields = "user";

const loading = ref({
	user: false,
});

const userData = ref({} as IUser);

const initSessionData = async () => {
	await executeAsync("user", async () => {
		const isAuth = isAuthenticated();
		if (!isAuth) {
			router.push("/login");
		} else {
			await executeAsync("user", async () => {
				const user = await getMyUser();

				if (user) {
					userData.value = user;
				} else {
					router.push("/login");
				}
			});
		}
	});
};

onMounted(initSessionData);

const handleReloadMyUser = async (callback: () => void) => {
	return await executeAsync("user", async () => {
		const updatedUser = await getMyUser();

		userData.value = updatedUser;

		if (callback) {
			callback();
		}
	});
};

const executeAsync = async (
	loadingField: LoadingFields,
	exec: any,
	showToast = true,
	rethrowError = false
) => {
	loading.value[loadingField] = true;

	try {
		const result = await exec();
		loading.value[loadingField] = false;
		return result;
	} catch (error) {
		const err = error as ApiError;
		if (err.statusCode === 401) {
			router.push("/login");
		} else {
			const errMessage = err.message;

			showToast && errorToast(err, errMessage);

			if (
				process.env.NODE_ENV !== "production" &&
				showToast &&
				err.innerMessage
			) {
				errorToast(err, err.innerMessage);
			}

			if (rethrowError) {
				throw error;
			}
		}
	} finally {
		loading.value[loadingField] = false;
	}
};
</script>
