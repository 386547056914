<template>
	<thead>
		<tr>
			<th
				class="ho-head-checkbox ho-cell-static w-18 px-8 ui-test-checkbox-cell"
				v-if="selectable"
			>
				<input
					v-if="hasSelectAll"
					type="checkbox"
					name="select-all"
					v-model="isSelectAllMode"
					@change="handleSelectAllCheck"
				/>
			</th>
			<grid-head-cell
				v-for="(item, i) in columnsConfig"
				:key="i"
				:item="item"
				:loading="loading"
				:sortOptions="sortOptions"
				:style="getCellWidthStyle(item.width, item.minWidth)"
				:class="item.class"
				@onSortingChange="handleSortingChange"
			/>
		</tr>
	</thead>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import GridHeadCell from "./grid-head-cell.vue";
import IGridHeadCellConfig from "./interfaces/iGridHeadCellConfig";
import { getCellWidthStyle } from "./shared/gridUtils";
import ISortOptions from "../../interfaces/invoices/iSortOptions";

const props = defineProps({
	loading: Boolean,
	columnsConfig: {
		type: Array as () => IGridHeadCellConfig[],
	},
	selectAllMode: Boolean,
	hasSelectAll: {
		type: Boolean,
		default: () => false,
	},
	selectable: {
		type: Boolean,
		default: () => false,
	},
	sortOptions: {
		type: Object as () => ISortOptions,
	},
});

const emit = defineEmits(["onSortingChange", "onSelectAllChange"]);

const isSelectAllMode = ref(props.selectAllMode);

const handleSortingChange = (field: string, value: "asc" | "desc") => {
	emit("onSortingChange", field, value);
};

const handleSelectAllCheck = (ev: any) => {
	emit("onSelectAllChange", ev.target.checked);
};

watch(
	() => props.selectAllMode,
	(newValue) => {
		isSelectAllMode.value = newValue;
	}
);
</script>

<style lang="postcss" scoped>
table thead tr th.ho-head-actions:hover {
	border-right: none;
	@apply bg-transparent;
}
</style>
