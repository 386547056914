import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "export-form" }

import { useI18n } from 'vue-i18n';
import HoModalDialog from "../modal/ho-modal-dialog.vue";
import DeclarantDataForm from "../profile/declarantDataForm/index.vue";
import IProfile from "@/interfaces/users/iProfile";
import IDeclarantExtended from '@/interfaces/users/iDeclarantExtended';


export default /*@__PURE__*/_defineComponent({
  __name: 'export-vies-declaration',
  props: {
	profileData: Object as () => IProfile & IDeclarantExtended
},
  emits: ["onExportModalClose"],
  setup(__props, { emit: __emit }) {

const localization = useI18n();


const emit = __emit;

const handleDialogClose = (data: IProfile & IDeclarantExtended) => {
	emit("onExportModalClose", data);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(HoModalDialog, {
    headerText: _unref(localization).t('export-vies-declaration.export-vies'),
    width: 640,
    disableOk: "",
    hideFooter: ""
  }, {
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", null, _toDisplayString(_unref(localization).t('export-vies-declaration.export-after-fill')), 1),
        _createVNode(DeclarantDataForm, {
          vies: "",
          declarantData: __props.profileData,
          okButtonText: _unref(localization).t('export-vies-declaration.export'),
          formLabel: _unref(localization).t('export-vies-declaration.exported-by'),
          onOnClose: handleDialogClose
        }, null, 8, ["declarantData", "okButtonText", "formLabel"])
      ])
    ]),
    _: 1
  }, 8, ["headerText"]))
}
}

})