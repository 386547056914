import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "form-row" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "flex mt-4" }
const _hoisted_4 = { class: "form-cell flex-1" }
const _hoisted_5 = { class: "form-cell flex-1" }
const _hoisted_6 = { class: "form-cell flex-1" }
const _hoisted_7 = { class: "flex mt-8" }
const _hoisted_8 = { class: "form-cell flex-none w-[190px]" }
const _hoisted_9 = { class: "form-cell flex-none w-[190px]" }
const _hoisted_10 = { class: "flex mt-8" }
const _hoisted_11 = { class: "form-cell flex-none w-[190px]" }
const _hoisted_12 = { class: "form-cell flex-none w-[100px]" }
const _hoisted_13 = { class: "flex mt-8 mb-4" }
const _hoisted_14 = { class: "form-cell flex-auto" }

import { computed, ref, Ref } from "vue";
import { useI18n } from "vue-i18n";
import { getDeclarantValidators, ValidationField } from "./validations";
import HoForm from "../../form/ho-form.vue";
import HoText from "../../form/ho-text.vue";
import HoSelect from "../../form/ho-select.vue";
import IProfile from "@/interfaces/users/iProfile";
import IDeclarantExtended from "@/interfaces/users/iDeclarantExtended";


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
	declarantData: {
		type: Object as () => IProfile & IDeclarantExtended,
	},
	formLabel: {
		type: String,
	},
	okButtonText: {
		type: String,
	},
},
  emits: ["onClose"],
  setup(__props, { emit: __emit }) {

const localization = useI18n();

const props = __props;
const declarantOptions = [
	{
		label: localization.t("declarant-data-dialog.declarant-representative"),
		value: "R",
	},
	{
		label: localization.t("declarant-data-dialog.declarant-attorney"),
		value: "A",
	},
];
const emit = __emit;

const validators = getDeclarantValidators(localization.t);

const formData = ref({ ...props.declarantData });

const validState: Ref<{ [key: string]: boolean }> = ref({
	firstName: formData.value.firstName ? true : false,
	middleName: formData.value.middleName ? true : false,
	lastName: formData.value.lastName ? true : false,
	ucn: formData.value.ucn ? true : false,
	postalCode: formData.value.postalCode ? true : false,
	address: formData.value.address ? true : false,
	city: formData.value.city ? true : false,
	declarantType: formData.value.declarantType ? true : false,
});

const isValid = computed(() => {
	return (
		validState.value.firstName &&
		validState.value.middleName &&
		validState.value.lastName &&
		validState.value.ucn &&
		validState.value.city &&
		validState.value.address &&
		validState.value.lastName &&
		validState.value.postalCode &&
		validState.value.declarantType
	);
});

const handleModalCancel = () => {
	emit("onClose");
};

const handleModalOk = () => {
	emit("onClose", formData.value);
};

const handleValidationStateChange = (
	fieldKey: ValidationField,
	value: boolean
) => {
	validState.value[fieldKey] = value;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(HoForm, {
    modelValue: formData.value,
    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((formData).value = $event)),
    class: "form-gray mt-4",
    okButtonText: __props.okButtonText || _unref(localization).t('common.update'),
    cancelButtonText: _unref(localization).t('common.cancel'),
    disableOk: !isValid.value,
    onOnOk: handleModalOk,
    onOnCancel: handleModalCancel
  }, {
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (__props.formLabel)
          ? (_openBlock(), _createElementBlock("h3", _hoisted_2, _toDisplayString(__props.formLabel), 1))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(HoText, {
              label: _unref(localization).t('common.profile.labels.first-name'),
              modelValue: formData.value.firstName,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((formData.value.firstName) = $event)),
              modelKey: "firstName",
              validation: _unref(validators).firstName,
              onOnValidStateChange: handleValidationStateChange
            }, null, 8, ["label", "modelValue", "validation"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(HoText, {
              label: _unref(localization).t('common.profile.labels.middle-name'),
              modelValue: formData.value.middleName,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((formData.value.middleName) = $event)),
              modelKey: "middleName",
              validation: _unref(validators).middleName,
              onOnValidStateChange: handleValidationStateChange
            }, null, 8, ["label", "modelValue", "validation"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(HoText, {
              label: _unref(localization).t('common.profile.labels.last-name'),
              modelValue: formData.value.lastName,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((formData.value.lastName) = $event)),
              modelKey: "lastName",
              validation: _unref(validators).lastName,
              onOnValidStateChange: handleValidationStateChange
            }, null, 8, ["label", "modelValue", "validation"])
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createVNode(HoText, {
              label: _unref(localization).t('common.profile.labels.ucn'),
              modelValue: formData.value.ucn,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((formData.value.ucn) = $event)),
              modelKey: "ucn",
              validation: _unref(validators).ucn,
              onOnValidStateChange: handleValidationStateChange
            }, null, 8, ["label", "modelValue", "validation"])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(HoSelect, {
              label: _unref(localization).t('common.profile.labels.declarant-type'),
              modelValue: formData.value.declarantType,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((formData.value.declarantType) = $event)),
              modelKey: "declarantType",
              "select-options-list": declarantOptions,
              onOnValidStateChange: handleValidationStateChange
            }, null, 8, ["label", "modelValue"])
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createVNode(HoText, {
              label: _unref(localization).t('common.profile.labels.city'),
              modelValue: formData.value.city,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((formData.value.city) = $event)),
              modelKey: "city",
              validation: _unref(validators).city,
              onOnValidStateChange: handleValidationStateChange
            }, null, 8, ["label", "modelValue", "validation"])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createVNode(HoText, {
              label: _unref(localization).t('common.profile.labels.postal-code'),
              modelValue: formData.value.postalCode,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((formData.value.postalCode) = $event)),
              modelKey: "postalCode",
              validation: _unref(validators).postalCode,
              onOnValidStateChange: handleValidationStateChange
            }, null, 8, ["label", "modelValue", "validation"])
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            _createVNode(HoText, {
              label: _unref(localization).t('common.profile.labels.address'),
              modelValue: formData.value.address,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((formData.value.address) = $event)),
              modelKey: "address",
              validation: _unref(validators).address,
              onOnValidStateChange: handleValidationStateChange
            }, null, 8, ["label", "modelValue", "validation"])
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "okButtonText", "cancelButtonText", "disableOk"]))
}
}

})