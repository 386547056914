<template>
	<tr v-if="loading" class="ui-test-grid-anim-row">
		<td
			v-for="(v, k, i) in dummyData"
			class="ui-test-grid-anim-cell"
			:key="k"
			:style="getBodyCellWidthStyle(k)"
		>
			<span
				class="load-anim ui-test-grid-anim-span"
				:style="getAnimDelay(i)"
			></span>
		</td>
	</tr>
	<tr
		v-if="!loading && data && typeof data !== 'number'"
		class="ui-test-grid-data-row"
		:class="getBodyRowErrorClass(data)"
		@click.stop="handleCheckboxChange"
	>
		<td
			class="checkbox ho-cell-static w-18 pl-7 pt-5 ui-test-grid-checkbox-cell"
			v-if="selectable"
		>
			<input
				class="ui-test-grid-checkbox-input"
				type="checkbox"
				:value="data.number"
				v-model="checkedRowsVar"
				@click.stop="handleCheckboxChange"
			/>
		</td>
		<td
			class="ui-test-grid-data-cell"
			v-for="(v, k) in data"
			:key="k"
			:class="getCellClasses(k, v)"
			:style="getBodyCellWidthStyle(k)"
		>
			<span>{{ getParsedField(v) }}</span>
		</td>
	</tr>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { formatToUi } from "@/utils/dateTime";
import IGridHeadCellConfig from "./interfaces/iGridHeadCellConfig";
import { getCellWidthStyle } from "./shared/gridUtils";

const requiredFileds = [
	"customerVat",
	"customerCountry",
	"nraDocType",
	"number",
];

const props = defineProps({
	data: [Object as () => any, Number],
	columnsConfig: {
		type: Array as () => IGridHeadCellConfig[],
	},
	selectable: {
		type: Boolean,
		default: () => false,
	},
	checkedRows: {
		type: Array,
		default: () => [],
	},
	loading: {
		type: Boolean,
		default: () => false,
	},
});
const emit = defineEmits(["onCheckboxChange"]);

const checkedRowsVar = ref(props.checkedRows);
watch(
	() => props.checkedRows.length,
	() => {
		if (props.selectable) {
			checkedRowsVar.value = [...props.checkedRows];
		}
	},
	{
		immediate: true,
	}
);
const getParsedField = (field: Date | number | string | null) => {
	if (field instanceof Date) {
		return formatToUi(field);
	} else if (typeof field === "number") {
		const number = new Intl.NumberFormat("en-US", {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		}).format(field);

		return number;
	}

	return field;
};

const getBodyCellWidthStyle = (key: string) => {
	const width = (props.columnsConfig || []).find((c) => c.key === key)?.width;
	const minWidth = (props.columnsConfig || []).find(
		(c) => c.key === key
	)?.minWidth;
	return getCellWidthStyle(width, minWidth);
};

const getAnimDelay = (index: number) => {
	return `animation-delay: 0.${index}s`;
};
const getCellClasses = (key: string, value: Date | string | number | null) => {
	let result = "";

	if (requiredFileds.includes(key) && !value) {
		result = "error-cell";
	}

	const columnConfig = props.columnsConfig?.find((conf) => conf.key === key);
	if (columnConfig && columnConfig.class) {
		result = `${result} ${columnConfig.class}`;
	}

	return result;
};

const getBodyRowErrorClass = (rowData: any) => {
	let result = "";
	for (const [key, value] of Object.entries(rowData)) {
		if (requiredFileds.includes(key) && !value) {
			result = "error-row";
		}
	}
	return result;
};

const handleCheckboxChange = () => {
	if (props.selectable) {
		const data = props.data;
		emit("onCheckboxChange", data.number);
	}
};

const dummyData = {
	nraDocType: "",
	number: "",
	invoiceDate: new Date(),
	date: new Date(),
	customerVat: "",
	customer: "",
	customerCountry: "",
	taxBase: 0,
	tax: 0,
	status: "",
};
</script>

<style lang="postcss" scoped>
.error-cell {
	background-color: #ffb0b0;
}
tr.error-row {
	background-color: #ffdede;
}
span.load-anim {
	display: block;
	height: 1.4rem;
	background-color: rgba(255, 255, 255, 0.8);
	border-radius: 0.7rem;
	animation: fadeIn 0.5s infinite alternate;
}
tbody.loading tr,
tbody.loading tr:hover td {
	background-color: transparent;
	cursor: initial;
}
</style>
