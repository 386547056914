import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "dialog-info-container"
}

import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import HoModalDialog from "../modal/ho-modal-dialog.vue";
import ChangePassword from "./changePasswordForm/index.vue";
import IUser from "@/interfaces/users/iUser";


export default /*@__PURE__*/_defineComponent({
  __name: 'user-data-dialog',
  props: {
    userData: {
        type: Object as () => IUser,
    },
    loading: Boolean
},
  emits: ['onChangePasswordClose'],
  setup(__props, { emit: __emit }) {

const localization = useI18n();



const emit = __emit;

const openChangePasswordDialog = ref(false);

const userDataDialogLabel = computed(() => {
    let label = localization.t('user-data-dialog.my-profile-title');
    if (openChangePasswordDialog.value) {
        label = localization.t('user-data-dialog.change-password');
    }

    return label;
})

const handlePasswordDialogClose = (oldPassword: string, newPassword: string) => {
    emit('onChangePasswordClose', oldPassword, newPassword);
    openChangePasswordDialog.value = false;
};

const handleModalCancel = () => {
    emit('onChangePasswordClose');
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(HoModalDialog, {
    headerText: userDataDialogLabel.value,
    hideCancel: false,
    hideFooter: openChangePasswordDialog.value,
    disableOk: openChangePasswordDialog.value,
    width: 480,
    okButtonText: _unref(localization).t('user-data-dialog.change-password'),
    cancelButtonText: _unref(localization).t('common.close'),
    onOnOk: _cache[0] || (_cache[0] = () => openChangePasswordDialog.value = true),
    onOnCancel: handleModalCancel
  }, {
    body: _withCtx(() => [
      (!openChangePasswordDialog.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createTextVNode(_toDisplayString(_unref(localization).t('common.user.username')) + ": ", 1),
            _createElementVNode("b", null, _toDisplayString(__props.userData?.userName), 1),
            _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
            _createTextVNode(" " + _toDisplayString(_unref(localization).t('common.user.email')) + ": ", 1),
            _createElementVNode("b", null, _toDisplayString(__props.userData?.email), 1)
          ]))
        : _createCommentVNode("", true),
      (openChangePasswordDialog.value)
        ? (_openBlock(), _createBlock(ChangePassword, {
            key: 1,
            loading: __props.loading,
            onOnChangePasswordClose: handlePasswordDialogClose
          }, null, 8, ["loading"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["headerText", "hideFooter", "disableOk", "okButtonText", "cancelButtonText"]))
}
}

})