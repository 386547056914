import { GetTranslationFunc } from "@/types/translationTypes";
import { IValidator } from "@/utils/validator";

export type ValidationField =
	| "firstName"
	| "middleName"
	| "lastName"
	| "ucn"
	| "postalCode"
	| "declarantType"
	| "city"
	| "address";

export const getDeclarantValidators = (
	getTranslation: GetTranslationFunc
): { [key: string]: IValidator[] } => {
	const validators: {
		[key: string]: IValidator[];
	} = {
		firstName: [
			{
				type: "notEmpty",
				message: getTranslation(
					"common.profile.validators.first-name-required"
				),
			},
		],
		fullName: [
			{
				type: "len",
				options: {
					min: 3,
					max: 39,
				},
				message: getTranslation("common.profile.validators.full-name-length", {
					min: 3,
					max: 39,
				}),
			},
		],
		ucn: [
			{
				type: "pattern",
				options: {
					pattern: /^\d{10}$/,
				},
				message: getTranslation("common.profile.validators.ucn-digits", {
					exact: 10,
				}),
			},
		],
		declarantType: [
			{
				type: "notEmpty",
				message: getTranslation(
					"common.profile.validators.declarant-type-required"
				),
			},
		],
		lastName: [
			{
				type: "notEmpty",
				message: getTranslation("common.profile.validators.last-name-required"),
			},
		],
		address: [
			{
				type: "notEmpty",
				message: getTranslation("common.profile.validators.address-required"),
			},
		],
		city: [
			{
				type: "notEmpty",
				message: getTranslation("common.profile.validators.city-required"),
			},
		],
		postalCode: [
			{
				type: "notEmpty",
				message: getTranslation(
					"common.profile.validators.postal-code-required"
				),
			},
		],
		middleName: [
			{
				type: "notEmpty",
				message: getTranslation(
					"common.profile.validators.middle-name-required"
				),
			},
		],
	};

	return validators;
};
