
import { GetTranslationFunc } from '@/types/translationTypes';
import { IValidator } from '@/utils/validator';

export const getValidators = (getTranslation: GetTranslationFunc): { [key:string]: IValidator[] }  => {
	const validators: {
		[key:string]: IValidator[]
	} = {
		oldPassword: [{
				type: 'notEmpty',
				message: getTranslation('user-data-dialog.validators.old-password-required')
			}],
		newPassword: [{
				type: 'len',
				options: {
					min: 8
				},
				message: getTranslation('user-data-dialog.validators.password-length', { min: 8 })
			},{
				type: 'pattern',
				options: {
					pattern: /[ !"#$%&'()*+,-.\/:;<=>?@[\\\]^_`{|}~]{1,}/
				},
				message: getTranslation('user-data-dialog.validators.password-symbols', { min: 1 })
			},{
				type: 'pattern',
				options: {
					pattern: /\d{1,}/
				},
				message: getTranslation('user-data-dialog.validators.password-digits', { min: 1 })
			}],
		confirmPassword: [{
				type: 'notEmpty',
				message: getTranslation('user-data-dialog.validators.confirmation-required')
			}]
	}

	return validators;
}