import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "label" }
const _hoisted_2 = ["disabled"]

import { ref, Ref } from 'vue';
import { SelectorIcon, ChevronUpIcon, ChevronDownIcon } from '@heroicons/vue/outline';
import IGridHeadCellConfig from "./interfaces/iGridHeadCellConfig";
import ISortOptions from '../../interfaces/invoices/iSortOptions';


export default /*@__PURE__*/_defineComponent({
  __name: 'grid-head-cell',
  props: {
	item: {
		type: Object as () => IGridHeadCellConfig,
	},
	sortOptions: {
		type: Object as () => ISortOptions
	},
	loading: Boolean
},
  emits: [ 'onSortingChange'],
  setup(__props, { emit: __emit }) {

const props = __props;
const emit = __emit;
const sortActive: Ref<'asc' | 'desc' | undefined> = ref(props.item?.sortField?.selectedSort);

const onSortClick = () => {
	const newSortValue: 'asc' | 'desc' = sortActive.value === 'asc' ? 'desc' : 'asc';

	sortActive.value = newSortValue;

	emit('onSortingChange', props.item?.sortField?.field, newSortValue);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("th", null, [
    _createElementVNode("span", _hoisted_1, [
      _createTextVNode(_toDisplayString(__props.item?.label) + " ", 1),
      (__props.item?.sortField)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: _normalizeClass(["ho-icon-btn sort-btn ui-test-sort-button", { sorted: sortActive.value, asc: sortActive.value ==='asc', active: __props.item.sortField.field === __props.sortOptions?.field }]),
            onClick: onSortClick,
            disabled: __props.loading
          }, [
            _createVNode(_unref(SelectorIcon), { class: "ho-btn-state-normal text-gray-400" }),
            _createVNode(_unref(SelectorIcon), { class: "ho-btn-state-hover" }),
            _createVNode(_unref(ChevronUpIcon), {
              class: _normalizeClass(["ho-btn-state-up", { active: sortActive.value ==='asc'}])
            }, null, 8, ["class"]),
            _createVNode(_unref(ChevronDownIcon), {
              class: _normalizeClass(["ho-btn-state-down", { active: sortActive.value ==='desc'}])
            }, null, 8, ["class"])
          ], 10, _hoisted_2))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})